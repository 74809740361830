.tos-refuse {
  /* Overwrite default modal styles */
  [data-dialog-actions] {
    @apply flex-nowrap;
  }

  a {
    @apply underline text-secondary;
  }
}

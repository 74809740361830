@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  /* Generated using: https://gwfh.mranftl.com/fonts */
  /* source-sans-pro-regular - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.eot"); /* IE9 Compat Modes */
    src: url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.eot?#iefix") format("embedded-opentype"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2") format("woff2"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff") format("woff"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.ttf") format("truetype"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.svg#SourceSansPro") format("svg"); /* Legacy iOS */
  }
  /* source-sans-pro-600 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    src: url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.eot"); /* IE9 Compat Modes */
    src: url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.eot?#iefix") format("embedded-opentype"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.woff2") format("woff2"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.woff") format("woff"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.ttf") format("truetype"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.svg#SourceSansPro") format("svg"); /* Legacy iOS */
  }
  /* source-sans-pro-700 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    src: url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.eot"); /* IE9 Compat Modes */
    src: url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.eot?#iefix") format("embedded-opentype"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2") format("woff2"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff") format("woff"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.ttf") format("truetype"), url("fonts/decidim/source-sans-pro-v21-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.svg#SourceSansPro") format("svg"); /* Legacy iOS */
  }
  .h1 {
    @apply font-sans font-bold text-4xl;
  }
  .h2 {
    @apply font-sans font-bold text-3xl;
  }
  .h3 {
    @apply font-sans font-semibold text-2xl;
  }
  .h4 {
    @apply font-sans font-semibold text-xl;
  }
  .h5 {
    @apply font-sans font-semibold text-lg;
  }
  .h6 {
    @apply font-sans font-semibold text-md;
  }
  .decorator {
    @apply relative after:absolute after:content-[""] after:h-1 after:w-10 after:top-[calc(100%+0.25rem)] after:left-0 after:bg-tertiary after:rounded-b;
  }
  .title-decorator {
    @apply h2 md:h1 decorator;
  }
  /* default styles for those dynamic text blocks */
  .editor-content {
    @apply prose max-w-full text-gray-2 text-lg prose-a:text-secondary break-words prose-ul:pl-4;
    /* The editor-library adds an empty <p>. */
    /* stylelint-disable selector-pseudo-class-no-unknown */
  }
  .editor-content iframe {
    @apply w-full block max-w-[600px] max-h-[70vh] h-[450px] my-10 mx-auto;
  }
  .editor-content p {
    @apply my-0;
  }
  .editor-content p:not(:empty) + p,
  .editor-content :where(h2, h3, h4, h5, h6) + span {
    @apply my-5;
  }
  .editor-content h2,
  .editor-content h3,
  .editor-content h4,
  .editor-content h5,
  .editor-content h6 {
    @apply mt-0;
  }
  .editor-content p:not(:empty) + h2 {
    @apply mt-8;
  }
  .editor-content p:not(:empty) + h3 {
    @apply mt-6;
  }
  .editor-content p:not(:empty) + h4 {
    @apply mt-4;
  }
  .editor-content p:not(:empty) + h5,
  .editor-content p:not(:empty) + h6 {
    @apply mt-2;
  }
  .editor-content :where(h2, h3, h4, h5, h6) br {
    @apply hidden;
  }
  .rich-text-display ul,
  .rich-text-display ol {
    @apply mb-4;
  }
  .rich-text-display .editor-content-videoEmbed {
    @apply my-10 mx-auto;
  }
  .rich-text-display ul ul,
  .rich-text-display ul ol,
  .rich-text-display ol ol,
  .rich-text-display ol ul,
  .rich-text-display li p,
  .editor-container .editor-input .ProseMirror ul ul,
  .editor-container .editor-input .ProseMirror ul ol,
  .editor-container .editor-input .ProseMirror ol ol,
  .editor-container .editor-input .ProseMirror ol ul,
  .editor-container .editor-input .ProseMirror li p {
    @apply mb-0;
  }
  .rich-text-display ol[data-type=a],
  .editor-container .editor-input .ProseMirror ol[data-type=a] {
    @apply list-[lower-alpha];
  }
  .rich-text-display ol[data-type=A],
  .editor-container .editor-input .ProseMirror ol[data-type=A] {
    @apply list-[upper-alpha];
  }
  .rich-text-display ol[data-type=i],
  .editor-container .editor-input .ProseMirror ol[data-type=i] {
    @apply list-[lower-roman];
  }
  .rich-text-display ol[data-type=I],
  .editor-container .editor-input .ProseMirror ol[data-type=I] {
    @apply list-[upper-roman];
  }
  .rich-text-display .editor-content-videoEmbed,
  .editor-container .editor-input .ProseMirror .editor-content-videoEmbed {
    @apply max-w-screen-sm;
  }
  .rich-text-display .editor-content-videoEmbed > div,
  .editor-container .editor-input .ProseMirror .editor-content-videoEmbed > div {
    @apply relative h-0 w-full pb-[56.25%]; /* 16:9 */
  }
  .rich-text-display .editor-content-videoEmbed iframe,
  .editor-container .editor-input .ProseMirror .editor-content-videoEmbed iframe {
    @apply absolute block top-0 left-0 w-full h-full;
  }
  .rich-text-display .editor-indent-1,
  .editor-container .editor-input .ProseMirror .editor-indent-1 {
    @apply ml-4;
  }
  .rich-text-display .editor-indent-2,
  .editor-container .editor-input .ProseMirror .editor-indent-2 {
    @apply ml-8;
  }
  .rich-text-display .editor-indent-3,
  .editor-container .editor-input .ProseMirror .editor-indent-3 {
    @apply ml-12;
  }
  .rich-text-display .editor-indent-4,
  .editor-container .editor-input .ProseMirror .editor-indent-4 {
    @apply ml-16;
  }
  .rich-text-display .editor-indent-5,
  .editor-container .editor-input .ProseMirror .editor-indent-5 {
    @apply ml-20;
  }
  .rich-text-display .editor-indent-6,
  .editor-container .editor-input .ProseMirror .editor-indent-6 {
    @apply ml-24;
  }
  .rich-text-display .editor-indent-7,
  .editor-container .editor-input .ProseMirror .editor-indent-7 {
    @apply ml-28;
  }
  .rich-text-display .editor-indent-8,
  .editor-container .editor-input .ProseMirror .editor-indent-8 {
    @apply ml-32;
  }
  .rich-text-display .editor-indent-9,
  .editor-container .editor-input .ProseMirror .editor-indent-9 {
    @apply ml-36;
  }
  .rich-text-display .editor-indent-10,
  .editor-container .editor-input .ProseMirror .editor-indent-10 {
    @apply ml-40;
  }
}
@layer components {
  .layout-container {
    @apply flex flex-col min-h-screen;
  }
  .layout-container__skip {
    @apply absolute z-10 left-0 -translate-x-full py-1 px-4 bg-primary rounded-br-lg text-white cursor-pointer transition focus:translate-x-0;
  }
  .layout-container [data-content] {
    @apply relative flex flex-col;
  }
  .layout-1col {
    @apply container grid grid-rows-[max-content] grid-cols-12 auto-rows-max mb-12 grow;
  }
  .layout-1col.cols-6 > * {
    @apply col-span-12 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6 self-start;
  }
  .layout-1col.cols-8 > * {
    @apply col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 self-start;
  }
  .layout-1col.cols-10 > * {
    @apply col-span-12 lg:col-start-2 lg:col-span-10 self-start;
  }
  .layout-1col footer {
    @apply mb-12;
  }
  .layout-2col {
    @apply md:grid grid-cols-12 container grow auto-rows-max;
  }
  .layout-2col__aside {
    @apply col-span-4 lg:col-span-3 md:pr-16 py-6 md:py-12 gap-6 md:gap-12 flex flex-col justify-between items-start md:justify-start before:content-[""] before:absolute before:top-0 before:left-0 before:h-full before:w-1/2 before:-z-10 md:before:bg-background;
  }
  .layout-2col__main {
    @apply col-span-8 lg:col-span-9 bg-white md:pl-16 py-6 md:py-12 min-h-[60vh];
  }
  .layout-2col__reverse .layout-2col__aside {
    @apply order-last pr-0 before:left-full before:-translate-x-full;
  }
  .layout-2col__reverse .layout-2col__main {
    @apply pl-0 md:pr-16;
  }
  .layout-item {
    @apply container grid grid-cols-1 lg:grid-cols-12 py-4 lg:py-24 gap-12 lg:gap-x-0 pt-12;
  }
  .layout-item__main {
    @apply xl:col-start-2 lg:col-span-8 xl:col-span-7 relative;
  }
  .layout-item__aside {
    @apply lg:col-start-10 xl:col-start-10 lg:col-span-3 xl:col-span-2;
  }
  .layout-item__aside--footer {
    @apply order-last;
  }
  .layout-item__back {
    @apply mb-4 lg:mb-0 lg:absolute lg:-left-[1em] lg:-top-8 lg:-translate-y-full;
  }
  .layout-item__arrow {
    @apply fixed top-1/2 py-2 bg-background before:content-[""] before:block before:absolute before:-translate-y-1/2 before:top-1/2 before:left-1/2 before:w-16 md:before:w-96 before:h-16 md:before:h-96 before:rounded-full;
  }
  .layout-item__arrow.prev {
    @apply -translate-x-1/2 md:translate-x-0 pl-4 pr-0 md:pr-1 left-0 rounded-r before:-translate-x-1/2 md:before:-translate-x-3/4 hover:-translate-x-1/4 transition;
  }
  .layout-item__arrow.next {
    @apply translate-x-1/2 md:translate-x-0 pr-4 pl-0 md:pl-1 right-0 rounded-l before:-translate-x-1/2 md:before:-translate-x-1/4 hover:translate-x-1/4 transition;
  }
  .layout-item__arrow svg {
    @apply w-8 h-8 text-secondary fill-current;
  }
  .layout-main__section + .layout-main__section,
  .layout-aside__section + .layout-aside__section,
  .layout-item__aside--footer > * + * {
    --vertical-spacing: 2.5rem;
    @apply mt-[var(--vertical-spacing)];
  }
  .layout-main__heading {
    @apply space-y-8;
  }
  .layout-main__heading .layout-author.has_status {
    @apply mt-6;
  }
  .layout-main__heading .layout-author.has_status:last-of-type {
    @apply mt-2;
  }
  .layout-author {
    @apply flex items-center gap-4;
    /* overwrite default dropdown styles */
    /* overwrite default dropdown styles */
  }
  .layout-author [data-target*=dropdown] {
    @apply w-auto cursor-pointer text-secondary text-2xl flex;
  }
  .layout-author [id*=dropdown-menu][aria-hidden=true] {
    @apply md:hidden;
  }
  .layout-author .dropdown__bottom {
    @apply [&>li]:pl-0;
  }
  .layout-author .dropdown__bottom .button {
    @apply [&>svg]:order-1 [&>span]:order-2;
  }
  .layout-aside__buttons {
    @apply space-y-4;
  }
  .layout-main__buttons {
    @apply flex gap-x-4 items-center;
  }
  .layout-aside__ctas-buttons {
    @apply md:relative inset-x-0 bottom-0 z-30 md:z-0 flex flex-row-reverse md:flex-col items-center md:items-stretch last:[&>button]:font-semibold first:[&>button]:text-lg justify-around bg-white md:bg-transparent gap-4 md:gap-0 p-4 md:p-0 shadow-inner md:shadow-none first:[&>*]:grow md:first:[&>*]:grow-0 last:[&>*]:w-1/2 md:last:[&>*]:w-auto h-20 md:h-fit first:[&>button]:py-3;
  }
  .layout-aside__ctas-buttons .meeting__aside-progress,
  .layout-aside__ctas-buttons .proposals__aside-progress {
    @apply flex flex-col-reverse md:flex-col md:w-auto;
  }
  .survey-section-buttons {
    @apply flex-row last:[&>button]:font-semibold md:[&>button]:font-normal last:[&>button]:text-lg md:last:[&>button]:text-sm md:first:[&>button]:text-sm last:[&>button]:py-3 md:last:[&>button]:py-1.5 md:first:[&>button]:py-1.5;
  }
  .button {
    @apply inline-flex items-center justify-center font-semibold text-center border border-transparent rounded transition cursor-pointer [&>svg]:flex-none [&>svg]:fill-current [&>span]:text-center [&>span]:break-words focus:outline focus:outline-2 focus:outline-offset-2;
  }
  .button__xs {
    @apply text-sm [&:not([class*="button\\_\\_text"])]:px-3 [&:not([class*="button\\_\\_text"])]:py-1 gap-1;
  }
  .button__sm {
    @apply text-sm [&:not([class*="button\\_\\_text"])]:px-4 [&:not([class*="button\\_\\_text"])]:py-1.5 gap-1.5;
  }
  .button__lg {
    @apply text-lg [&:not([class*="button\\_\\_text"])]:px-6 [&:not([class*="button\\_\\_text"])]:py-3 gap-2;
  }
  .button__xl {
    @apply text-xl [&:not([class*="button\\_\\_text"])]:px-8 [&:not([class*="button\\_\\_text"])]:py-4 gap-4;
  }
  .button__primary {
    @apply bg-primary text-white focus:outline-primary;
  }
  .button__secondary {
    @apply bg-secondary text-white focus:outline-secondary;
  }
  .button__tertiary {
    @apply bg-tertiary text-white focus:outline-tertiary;
  }
  .button__transparent {
    @apply bg-transparent text-white border border-white;
  }
  .button__transparent-primary {
    @apply border bg-white border-primary text-primary focus:outline-primary;
  }
  .button__transparent-secondary {
    @apply border bg-white border-secondary text-secondary focus:outline-secondary;
  }
  .button__transparent-tertiary {
    @apply border bg-white border-tertiary text-tertiary focus:outline-tertiary;
  }
  .button__text {
    @apply bg-transparent border-0 p-0 hover:underline text-black;
  }
  .button__text-primary {
    @apply bg-transparent border-0 p-0 hover:underline text-primary;
  }
  .button__text-secondary {
    @apply bg-transparent border-0 p-0 hover:underline text-secondary;
  }
  .button__text-tertiary {
    @apply bg-transparent border-0 p-0 hover:underline text-tertiary;
  }
  .button[disabled], .button.disabled {
    @apply text-gray-2 bg-background-3 border border-gray-2 cursor-not-allowed;
  }
  .button.is-hover, .button:not([disabled]):not([class*=button__text]):not(label):hover {
    @apply text-white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), theme("colors.secondary");
  }
  .button__pill {
    @apply bg-gray-5 text-secondary mx-0 rounded;
  }
  .button__pill:not(:last-child) {
    @apply rounded-r-none;
  }
  .button__pill + .button__pill {
    @apply rounded-r rounded-l-none;
  }
  .button__pill--active {
    @apply bg-secondary text-white;
  }
  .button__pill--active .button__pill--count {
    @apply bg-white text-secondary;
  }
  .button__pill--count {
    @apply rounded-full bg-secondary text-white inline-grid place-items-center h-[18px] w-[18px] ml-2 text-xs;
  }
  .layout-main__buttons .button__sm {
    @apply text-2xl [&:not([class*="button\\_\\_text"])]:px-1.5 [&:not([class*="button\\_\\_text"])]:py-1.5 gap-1.5
      lg:text-sm lg:[&:not([class*="button\\_\\_text"])]:px-4;
  }
  .layout-main__buttons .button__sm span {
    @apply hidden lg:flex;
  }
  .layout-main__buttons .button__transparent-secondary {
    @apply p-0 border-0 lg:border lg:border-secondary;
  }
  .label {
    --success: #16592e;
    --bg-success: #c4ecd0;
    --alert: #b9081b;
    --bg-alert: #ffdee3;
    --warning: #ad4910;
    --bg-warning: #ffeebd;
    @apply bg-background text-gray-2 rounded inline-flex items-center gap-1 px-2 py-1 h-min font-semibold text-sm;
  }
  .label.success {
    @apply bg-[var(--bg-success)] text-[var(--success)];
  }
  .label.alert {
    @apply bg-[var(--bg-alert)] text-[var(--alert)];
  }
  .label.warning {
    @apply bg-[var(--bg-warning)] text-[var(--warning)];
  }
  .label.reverse {
    @apply bg-gray-2 text-white;
  }
  #form-search-mobile {
    @apply py-1.5 px-4 bg-gray-5 rounded-lg border border-gray outline outline-1 outline-transparent;
  }
  #form-search-mobile input[type=text] {
    @apply bg-gray-5;
  }
  .form-defaults {
    /* text-like inputs */
    /* option-like inputs */
  }
  .form-defaults input[type=date],
  .form-defaults input[type=datetime-local],
  .form-defaults input[type=email],
  .form-defaults input[type=month],
  .form-defaults input[type=number],
  .form-defaults input[type=password],
  .form-defaults input[type=search],
  .form-defaults input[type=tel],
  .form-defaults input[type=text],
  .form-defaults input[type=time],
  .form-defaults input[type=url],
  .form-defaults input[type=week],
  .form-defaults select,
  .form-defaults textarea {
    /* read-only can be applied only to input and textarea, otherwise selects will be set as readonly */
  }
  .form-defaults input[type=date]:not(.reset-defaults),
  .form-defaults input[type=datetime-local]:not(.reset-defaults),
  .form-defaults input[type=email]:not(.reset-defaults),
  .form-defaults input[type=month]:not(.reset-defaults),
  .form-defaults input[type=number]:not(.reset-defaults),
  .form-defaults input[type=password]:not(.reset-defaults),
  .form-defaults input[type=search]:not(.reset-defaults),
  .form-defaults input[type=tel]:not(.reset-defaults),
  .form-defaults input[type=text]:not(.reset-defaults),
  .form-defaults input[type=time]:not(.reset-defaults),
  .form-defaults input[type=url]:not(.reset-defaults),
  .form-defaults input[type=week]:not(.reset-defaults),
  .form-defaults select:not(.reset-defaults),
  .form-defaults textarea:not(.reset-defaults) {
    @apply inline-block px-4 py-2 border border-gray outline outline-1 outline-transparent rounded bg-background-2 text-black font-normal placeholder:text-gray focus:outline-2 focus:outline-secondary disabled:bg-background-3 disabled:text-gray-2 disabled:cursor-not-allowed;
  }
  .form-defaults input[type=date]:not(.reset-defaults).sm,
  .form-defaults input[type=datetime-local]:not(.reset-defaults).sm,
  .form-defaults input[type=email]:not(.reset-defaults).sm,
  .form-defaults input[type=month]:not(.reset-defaults).sm,
  .form-defaults input[type=number]:not(.reset-defaults).sm,
  .form-defaults input[type=password]:not(.reset-defaults).sm,
  .form-defaults input[type=search]:not(.reset-defaults).sm,
  .form-defaults input[type=tel]:not(.reset-defaults).sm,
  .form-defaults input[type=text]:not(.reset-defaults).sm,
  .form-defaults input[type=time]:not(.reset-defaults).sm,
  .form-defaults input[type=url]:not(.reset-defaults).sm,
  .form-defaults input[type=week]:not(.reset-defaults).sm,
  .form-defaults select:not(.reset-defaults).sm,
  .form-defaults textarea:not(.reset-defaults).sm {
    @apply px-1 py-0.5 text-sm;
  }
  .form-defaults input[type=date]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=datetime-local]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=email]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=month]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=number]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=password]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=search]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=tel]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=text]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=time]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=url]:not(.reset-defaults).sm-icon,
  .form-defaults input[type=week]:not(.reset-defaults).sm-icon,
  .form-defaults select:not(.reset-defaults).sm-icon,
  .form-defaults textarea:not(.reset-defaults).sm-icon {
    @apply pr-1 pl-6 py-0.5 text-sm;
  }
  .form-defaults input[type=date]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=datetime-local]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=email]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=month]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=number]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=password]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=search]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=tel]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=text]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=time]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=url]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults input[type=week]:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults select:not(.reset-defaults).is-invalid-input:not(:focus),
  .form-defaults textarea:not(.reset-defaults).is-invalid-input:not(:focus) {
    @apply outline-2 outline-alert;
  }
  .form-defaults input[type=date]:not(select),
  .form-defaults input[type=datetime-local]:not(select),
  .form-defaults input[type=email]:not(select),
  .form-defaults input[type=month]:not(select),
  .form-defaults input[type=number]:not(select),
  .form-defaults input[type=password]:not(select),
  .form-defaults input[type=search]:not(select),
  .form-defaults input[type=tel]:not(select),
  .form-defaults input[type=text]:not(select),
  .form-defaults input[type=time]:not(select),
  .form-defaults input[type=url]:not(select),
  .form-defaults input[type=week]:not(select),
  .form-defaults select:not(select),
  .form-defaults textarea:not(select) {
    @apply read-only:bg-background read-only:border-0 read-only:text-gray-2;
  }
  .form-defaults input[type=checkbox]:not(.reset-defaults),
  .form-defaults input[type=radio]:not(.reset-defaults) {
    @apply inline-block mr-2;
  }
  .form-defaults select {
    @apply appearance-none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8'%3e%3cpath fill='%233E4C5C' d='M5.99962 4.97656L10.1246 0.851562L11.303 2.0299L5.99962 7.33323L0.696289 2.0299L1.87462 0.851562L5.99962 4.97656Z'/%3e%3c/svg%3e");
    background-position: right 1rem center;
    background-repeat: no-repeat;
    background-size: 0.75rem;
  }
  .form-defaults select:not(.reset-defaults) {
    @apply pr-8;
  }
  .form-error {
    @apply mt-2 hidden font-normal text-sm text-alert;
  }
  .form-error.is-visible {
    @apply block;
  }
  .help-text {
    @apply block text-sm text-gray-2 font-normal prose-a:text-secondary prose-a:underline;
  }
  .form__wrapper {
    @apply flex flex-col py-10 gap-10 last:pb-0;
  }
  .form__wrapper-block {
    @apply py-10 last:pb-0 border-t-2 border-background flex flex-col gap-4 only:[&_*]:ml-auto [&_p]:text-gray-2;
  }
  .form__wrapper-checkbox-label {
    @apply text-md text-gray-2 font-normal prose-a:text-secondary prose-a:underline cursor-pointer;
  }
  .form__wrapper-checkbox-label + .form__wrapper-checkbox-label {
    @apply block;
  }
  .form__wrapper label:not(.form__wrapper-checkbox-label), .form__wrapper-block label:not(.form__wrapper-checkbox-label) {
    @apply font-semibold text-lg;
  }
  .form__wrapper .help-text {
    @apply mt-4;
  }
  .form__wrapper input[type=date],
  .form__wrapper input[type=datetime-local],
  .form__wrapper input[type=email],
  .form__wrapper input[type=month],
  .form__wrapper input[type=number],
  .form__wrapper input[type=password],
  .form__wrapper input[type=search],
  .form__wrapper input[type=tel],
  .form__wrapper input[type=text],
  .form__wrapper input[type=time],
  .form__wrapper input[type=url],
  .form__wrapper input[type=week],
  .form__wrapper select,
  .form__wrapper textarea {
    @apply w-full block mt-4;
  }
  .form__wrapper input[type=checkbox],
  .form__wrapper input[type=radio] {
    @apply mt-4;
  }
  .input-character-counter {
    @apply relative mt-4 block;
  }
  .input-character-counter input[type=date],
  .input-character-counter input[type=datetime-local],
  .input-character-counter input[type=email],
  .input-character-counter input[type=month],
  .input-character-counter input[type=number],
  .input-character-counter input[type=password],
  .input-character-counter input[type=search],
  .input-character-counter input[type=tel],
  .input-character-counter input[type=text],
  .input-character-counter input[type=time],
  .input-character-counter input[type=url],
  .input-character-counter input[type=week],
  .input-character-counter select,
  .input-character-counter textarea {
    @apply mt-0;
  }
  .input-character-counter__container {
    @apply -translate-y-full;
  }
  .input-character-counter__text {
    @apply font-normal inline-block text-xs text-gray-2 float-right mt-1;
  }
  .input-group__password {
    @apply w-full relative;
  }
  .input-group__password input {
    @apply w-full;
  }
  .input-group__password button {
    @apply absolute ltr:right-2 rtl:left-2 top-8;
  }
  .emoji__container {
    @apply relative;
  }
  .emoji__trigger {
    @apply relative block;
  }
  .emoji__button {
    @apply absolute right-4 bottom-4 cursor-pointer text-gray-2;
  }
  .emoji__button svg {
    width: 1em;
  }
  .emoji__reference {
    @apply block absolute bottom-0 right-0;
  }
  .emoji__decidim {
    @apply max-w-full z-50;
  }
  input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  .autoComplete_wrapper {
    display: block;
  }
  .old-password__wrapper {
    @apply p-10 border-2 border-solid border-secondary rounded;
    background-color: rgb(var(--secondary-rgb)/0.05);
  }
  [id*=dropdown-menu] {
    @apply flex flex-col py-0 mx-3.5 md:mx-0 border-t-0 border-gray-3 cursor-pointer;
  }
  [id*=dropdown-menu][aria-hidden=true] {
    @apply hidden md:flex;
  }
  [data-target*=dropdown] {
    @apply w-full md:hidden flex items-center justify-between gap-2 p-2 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden;
  }
  [data-target*=dropdown] > span {
    @apply hidden font-semibold text-secondary;
  }
  [data-target*=dropdown] > span:only-of-type, [data-target*=dropdown] > span.is-active {
    @apply flex items-center gap-2 [&_svg]:fill-current;
  }
  [data-target*=dropdown] > svg {
    @apply w-6 h-6 flex-none text-secondary fill-current;
  }
  [data-target*=dropdown][aria-expanded=false] > svg:last-of-type, [data-target*=dropdown][aria-expanded=true] > svg:first-of-type {
    @apply hidden;
  }
  [data-target*=dropdown][aria-expanded=true] > svg:last-of-type, [data-target*=dropdown][aria-expanded=false] > svg:first-of-type {
    @apply block;
  }
  [data-target=dropdown-menu-language-chooser] > span {
    @apply block font-semibold text-black text-xs;
  }
  [data-target=dropdown-menu-language-chooser] > svg {
    @apply w-5 h-6 flex-none text-xs font-normal text-black fill-current;
  }
  [data-target=dropdown-menu-language-chooser] > ul {
    @apply w-6 bg-gray;
  }
  [data-target=dropdown-menu-language-chooser] > ul > li {
    @apply w-6 bg-gray;
  }
  [data-target=dropdown-menu-language-chooser-mobile] > span {
    @apply block text-black font-normal text-sm;
  }
  [data-target=dropdown-menu-language-chooser-mobile] > svg {
    @apply w-5 h-6 flex-none text-xs font-normal text-black fill-current;
  }
  [data-target=dropdown-menu-language-chooser-mobile] > ul {
    @apply w-6 bg-gray;
  }
  [data-target=dropdown-menu-language-chooser-mobile] > ul > li {
    @apply w-6 bg-gray;
  }
  .dropdown {
    @apply absolute border-2 border-gray-3 rounded min-w-max p-4 drop-shadow-md text-left z-10;
    /*
      NOTE: the calculated value is the sum of the arrow offset position plus the half of the arrow size:
      - offset position: 20%
      - arrow size: 1.5rem
    */
    --arrow-offset: 20%;
    --arrow-size: 1.5rem;
    --arrow-visible-size: var(--arrow-size) * 0.5;
  }
  .dropdown > * {
    @apply relative z-10 p-3.5 first:pt-1.5 last:pb-1.5;
  }
  .dropdown__item {
    @apply gap-x-2 flex items-center text-secondary;
    font-size: 14px;
  }
  .dropdown__item > svg {
    @apply w-4 h-4 flex-none text-gray fill-current;
  }
  .dropdown__item:hover {
    @apply underline [&>svg]:text-secondary;
  }
  .dropdown__bottom {
    @apply top-full right-0 mt-3 translate-x-[calc(var(--arrow-offset)-var(--arrow-visible-size))] before:content-[""] before:absolute before:right-[var(--arrow-offset)] before:-top-2 before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-white before:rounded before:border-2 before:border-gray-3 after:content-[""] after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-white;
  }
  .login__box {
    @apply w-full md:w-1/2 flex flex-col items-start gap-4 border-2 border-background rounded p-6 cursor-pointer text-left;
  }
  .login__box-container {
    @apply flex flex-col md:flex-row gap-4 md:gap-12;
  }
  .login__box-icon {
    @apply w-8 h-8 text-tertiary fill-current;
  }
  .login__box-title {
    @apply text-2xl font-semibold;
  }
  .login__box-description {
    @apply text-lg text-gray-2;
  }
  .login__box-link {
    @apply inline-flex items-center gap-1 text-secondary font-semibold mt-auto;
  }
  .login__box:hover {
    @apply border-tertiary;
  }
  .login__box:hover .login__box-link {
    @apply underline;
  }
  .callout {
    @apply rounded p-4 border-l-4 border-secondary bg-secondary/5 space-y-2;
  }
  .callout.success {
    @apply border-success bg-success/5;
  }
  .callout.alert {
    @apply border-alert bg-alert/5;
  }
  .callout.warning {
    @apply border-warning bg-warning/5;
  }
  .callout + * {
    @apply mt-4;
  }
  .callout a {
    @apply text-secondary;
  }
  .callout[data-component=accordion] [id*=panel][aria-hidden=true] {
    @apply block max-h-14 overflow-hidden relative before:content-[""] before:absolute before:inset-0 before:h-full before:w-full before:bg-gradient-to-b before:from-transparent before:to-white after:content-[""] after:absolute after:inset-0 after:h-full after:w-full after:bg-gradient-to-b after:from-transparent after:to-secondary/5;
  }
  .callout[data-component=accordion].success [id*=panel][aria-hidden=true] {
    @apply after:to-success/5;
  }
  .callout[data-component=accordion].alert [id*=panel][aria-hidden=true] {
    @apply after:to-alert/5;
  }
  .callout[data-component=accordion].warning [id*=panel][aria-hidden=true] {
    @apply after:to-warning/5;
  }
  .callout[data-component=accordion] [aria-expanded=false] > svg:last-of-type,
  .callout[data-component=accordion] [aria-expanded=false] > span:last-of-type,
  .callout[data-component=accordion] [aria-expanded=true] > span:first-of-type,
  .callout[data-component=accordion] [aria-expanded=true] > svg:first-of-type {
    @apply hidden;
  }
  .callout[data-component=accordion] [aria-expanded=true] > svg:last-of-type,
  .callout[data-component=accordion] [aria-expanded=true] > span:last-of-type,
  .callout[data-component=accordion] [aria-expanded=false] > span:first-of-type,
  .callout[data-component=accordion] [aria-expanded=false] > svg:first-of-type {
    @apply block;
  }
  [data-component=accordion] [id*=panel][aria-hidden=true] {
    display: none;
  }
  [data-component=accordion] [id*=comment][class=comment-reply][aria-hidden=true] {
    display: none;
  }
  [data-component=accordion] [id*=comment][class=comment-reply][aria-hidden=false] {
    display: block;
  }
  .card-accordion {
    @apply border-b border-gray-3 mt-6;
  }
  .card-accordion-title {
    @apply text-primary font-semibold text-xl inline-block;
  }
  .card-accordion-divider-button {
    @apply flex items-center justify-between items-end w-full;
  }
  .card-accordion-divider-button svg {
    @apply inline-block w-6 h-6 fill-primary;
  }
  .card-accordion-divider-button[aria-expanded=true] svg {
    @apply rotate-180 transition-transform;
  }
  .card-accordion-section {
    @apply pb-8;
  }
  .card-accordion-section ul {
    @apply mb-0;
  }
  .tos {
    @apply rounded border border-gray p-4;
  }
  .tos__buttons {
    @apply flex items-center justify-center gap-4 mt-4;
  }
  .vertical-tabs {
    @apply grid gap-10 lg:gap-20 grid-rows-[max-content] grid-cols-1 md:grid-cols-10 first:[&>*]:col-span-full md:first:[&>*]:col-span-3 last:[&>*]:col-span-full md:last:[&>*]:col-span-7;
  }
  .vertical-tabs__list {
    @apply md:bg-background rounded divide-y-0 md:divide-y divide-gray-3;
  }
  .vertical-tabs__list a {
    @apply py-2 md:p-4 block w-full h-full text-secondary hover:underline;
  }
  .vertical-tabs__list li.is-active a {
    @apply border-l-0 md:border-l-4 border-primary font-semibold;
  }
  .vertical-tabs nav {
    @apply rounded border-4 border-background md:border-0 self-start;
  }
  .vertical-tabs [id*=dropdown-menu] {
    @apply flex flex-col mx-3.5 md:mx-0 md:px-0 py-2 md:py-0 border-t md:border-t-0 border-gray-3 cursor-pointer;
  }
  .vertical-tabs [id*=dropdown-menu][aria-hidden=true] {
    @apply hidden md:flex;
  }
  .vertical-tabs [data-target*=dropdown] {
    @apply w-full md:hidden flex items-center justify-between gap-2 p-2 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden;
  }
  .vertical-tabs [data-target*=dropdown] > span {
    @apply font-semibold flex items-center gap-2 text-secondary [&_svg]:fill-current;
  }
  .vertical-tabs [data-target*=dropdown] > svg {
    @apply w-8 h-8 flex-none text-secondary fill-current;
  }
  .vertical-tabs [data-target*=dropdown][aria-expanded=false] > svg:last-of-type, .vertical-tabs [data-target*=dropdown][aria-expanded=true] > svg:first-of-type {
    @apply hidden;
  }
  .vertical-tabs [data-target*=dropdown][aria-expanded=true] > svg:last-of-type, .vertical-tabs [data-target*=dropdown][aria-expanded=false] > svg:first-of-type {
    @apply block;
  }
  .flash {
    @apply flex justify-start gap-4 border-l-4 border-secondary my-4 p-4 bg-secondary/5;
  }
  .flash__icon svg {
    @apply w-12 h-12 fill-current flex-none;
  }
  .flash__title {
    @apply font-semibold text-lg;
  }
  .flash__message {
    @apply text-black font-medium text-md flex-col;
  }
  .flash__message a:not(.button) {
    @apply underline text-secondary;
  }
  .flash__message a:not(.button) svg {
    @apply text-secondary;
  }
  .flash__message p {
    @apply font-normal text-md leading-6;
  }
  .flash__message ul {
    @apply ml-4;
  }
  .flash__message ul li {
    @apply list-disc;
  }
  .flash__message-body {
    @apply text-gray-2 text-sm font-normal prose-a:text-secondary prose-a:underline;
  }
  .flash .close-button {
    @apply ml-auto self-start;
  }
  .flash .close-button svg {
    @apply w-5 h-5 fill-current;
  }
  .flash.primary svg {
    @apply text-secondary;
  }
  .flash.secondary .flash__icon, .flash.info .flash__icon {
    @apply text-secondary;
  }
  .flash.success {
    @apply border-success bg-success/5;
  }
  .flash.success .flash__icon {
    @apply text-success;
  }
  .flash.alert {
    @apply border-alert bg-alert/5;
  }
  .flash.alert .flash__icon {
    @apply text-alert;
  }
  .flash.warning {
    @apply border-warning bg-warning/5;
  }
  .flash.warning .flash__icon {
    @apply text-warning;
  }
  .static-map {
    @apply cursor-pointer overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover;
  }
  .static-map__container {
    @apply flex flex-col-reverse md:flex-row items-start md:items-center gap-2 [&>*]:w-full md:[&>*]:w-1/2 last:[&>*]:h-[120px] last:[&>*]:grow;
  }
  .static-map__admin {
    @apply flex justify-center;
  }
  .static-map__admin img {
    @apply w-52;
  }
  .filter {
    @apply flex gap-2 p-1.5 relative;
  }
  .filter label {
    @apply flex items-center gap-2 p-1.5 rounded cursor-pointer relative w-full;
  }
  .filter-container {
    @apply rounded border-4 border-background md:border-0;
  }
  .filter-container [id*=dropdown-menu] {
    @apply gap-1;
  }
  .filter-container [data-controls*=panel] {
    @apply flex items-center justify-start gap-2 p-1.5 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden;
  }
  .filter-container [data-controls*=panel] > span {
    @apply text-gray-2 font-bold;
  }
  .filter-container [data-controls*=panel] > svg {
    @apply w-3.5 h-3.5 text-gray-2 fill-current;
  }
  .filter-container [data-controls*=panel][aria-expanded=false] > svg:last-of-type, .filter-container [data-controls*=panel][aria-expanded=true] > svg:first-of-type {
    @apply hidden;
  }
  .filter-container [data-controls*=panel][aria-expanded=true] > svg:last-of-type, .filter-container [data-controls*=panel][aria-expanded=false] > svg:first-of-type {
    @apply block;
  }
  .filter-container input[type=checkbox],
  .filter-container input[type=radio] {
    @apply w-3.5 h-3.5 relative z-10;
  }
  .filter-container input[type=checkbox]:checked, .filter-container input[type=checkbox]:indeterminate,
  .filter-container input[type=radio]:checked,
  .filter-container input[type=radio]:indeterminate {
    @apply accent-secondary;
  }
  .filter-container input[type=checkbox]:checked + span,
  .filter-container input[type=radio]:checked + span {
    @apply text-secondary font-semibold relative z-10;
  }
  .filter-container input[type=checkbox]:checked + span + span,
  .filter-container input[type=radio]:checked + span + span {
    @apply w-full absolute top-0 left-0 bg-background-4 h-full rounded block;
  }
  .filter-container [id*=panel] [id*=panel] {
    @apply ml-6;
  }
  .filter-container + .filter-container {
    @apply mt-4 md:mt-8;
  }
  .filter-skip {
    @apply absolute z-10 left-0 -translate-x-full py-1 px-4 bg-primary rounded-br-lg text-white cursor-pointer transition focus:translate-x-0;
  }
  .filter-help {
    @apply text-xs text-gray-2 mb-4;
  }
  .filter-search {
    @apply w-full relative bg-gray;
  }
  .filter-search input {
    @apply w-full;
  }
  .filter-search button {
    @apply absolute right-2 inset-y-2;
  }
  .filter svg {
    @apply w-4 h-4 flex-none text-gray fill-current;
  }
  .filter span {
    @apply text-sm text-gray-2 truncate whitespace-nowrap first-letter:uppercase;
  }
  .filter [data-controls*=panel] {
    @apply ml-auto p-0 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden rounded hover:bg-gray-2 hover:[&>svg]:text-white transition-colors;
  }
  .filter [data-controls*=panel][aria-expanded=false] > svg:last-of-type, .filter [data-controls*=panel][aria-expanded=true] > svg:first-of-type {
    @apply hidden;
  }
  .filter [data-controls*=panel][aria-expanded=true] > svg:last-of-type, .filter [data-controls*=panel][aria-expanded=false] > svg:first-of-type {
    @apply block;
  }
  .filter-container [type=radio]:checked + .filter, .filter-container [type=checkbox]:checked + .filter, .filter-container [id*=dropdown-menu] .is-active, .filter:hover {
    @apply bg-background-4;
  }
  .filter-container [type=radio]:checked + .filter svg, .filter-container [type=checkbox]:checked + .filter svg, .filter-container [id*=dropdown-menu] .is-active svg, .filter-container [type=checkbox]:indeterminate + .filter svg {
    @apply text-secondary;
  }
  .filter-container [type=radio]:checked + .filter span, .filter-container [type=checkbox]:checked + .filter span, .filter-container [id*=dropdown-menu] .is-active span, .filter-container [type=checkbox]:indeterminate + .filter span {
    @apply text-secondary font-semibold;
  }
  /* overwrite default dropdowns */
  [data-target=dropdown-menu-filters] {
    @apply px-0 justify-start [&>span]:text-gray-2;
  }
  [data-target=dropdown-menu-filters] > svg {
    @apply h-4 w-4 text-gray;
  }
  [data-target=dropdown-menu-filters] + [id=dropdown-menu-filters] {
    @apply mx-0;
  }
  .tag {
    @apply flex items-center gap-1 hover:underline;
  }
  .tag-container {
    @apply flex items-center gap-2 text-sm text-gray-2;
  }
  .tag svg {
    @apply w-3.5 h-3.5 text-gray fill-current;
  }
  .tab-x {
    @apply w-full flex gap-2 justify-center items-center text-sm text-gray-2 border-gray border-b-2 py-1 cursor-pointer;
  }
  .tab-x-container {
    @apply flex gap-1 [&>*]:grow;
  }
  .tab-x[aria-expanded=true], .tab-x:hover[disabled=false] {
    @apply text-secondary border-secondary;
  }
  .tab-x[aria-expanded=true] svg, .tab-x:hover[disabled=false] svg {
    @apply text-secondary;
  }
  .tab-x[disabled] {
    @apply opacity-50 cursor-not-allowed;
  }
  .tabs-1 {
    pointer-events: none;
  }
  .metadata__container {
    @apply mx-auto flex flex-col md:flex-row gap-2 md:gap-0 items-center justify-center;
  }
  .metadata__item {
    @apply md:px-6 border-background md:border-x first:pl-0 last:pr-0 first:border-l-0 last:border-r-0 text-sm text-gray-2;
  }
  .metadata__item strong {
    @apply font-bold text-black;
  }
  .metadata__item button,
  .metadata__item a {
    @apply underline text-sm text-secondary inline-block first-letter:uppercase;
  }
  [data-dialog] {
    @apply invisible opacity-0 fixed z-50 inset-0 bg-[rgba(0,0,0,0.25)] transition duration-300;
  }
  [data-dialog] > * {
    @apply absolute inset-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 w-[90%] lg:max-w-[900px] max-h-[95vh] h-fit overflow-y-auto p-6 bg-white z-50 rounded shadow-[0_4px_6px_rgba(211,211,211,0.25)];
  }
  [data-dialog] > * > svg:only-child {
    @apply w-8 h-8 mx-auto text-gray-2 fill-current animate-spin;
  }
  [data-dialog][aria-hidden=false] {
    @apply visible opacity-100;
  }
  [data-dialog] [data-dialog-closable] {
    @apply absolute top-4 right-4 text-2xl text-secondary;
  }
  [data-dialog] [data-dialog-container] {
    @apply grid grid-cols-[auto_1fr] items-start md:items-center gap-2 text-left;
  }
  [data-dialog] [data-dialog-container] > svg {
    @apply w-6 h-6 text-gray fill-current flex-none;
  }
  [data-dialog] [data-dialog-container] > :last-child {
    @apply col-span-2 md:col-span-1 md:col-start-2;
  }
  [data-dialog] [data-dialog-title] {
    @apply text-2xl text-black font-semibold focus:outline-none;
  }
  [data-dialog] [data-dialog-actions] {
    @apply flex justify-between items-center gap-2 md:gap-4 flex-wrap mt-10 last:[&>*]:ml-auto;
    /* 3 or more items spans the full width for mobile */
  }
  [data-dialog] [data-dialog-actions] > *:first-child:nth-last-child(n+3), [data-dialog] [data-dialog-actions] > *:first-child:nth-last-child(n+3) ~ * {
    @apply w-full md:w-auto;
  }
  [data-dialog][data-drawer] > * {
    @apply h-full max-h-full w-0 inset-0 translate-x-0 translate-y-0 right-0 left-auto max-w-[90%] lg:max-w-[900px] transition-none transition-[width] duration-500 overflow-visible;
  }
  [data-dialog][data-drawer][aria-hidden=false] > * {
    @apply w-[60%];
  }
  [data-dialog][data-drawer] [data-dialog-container] {
    @apply grid-cols-none;
  }
  [data-dialog][data-drawer] [data-dialog-container].layout-content {
    @apply bg-white;
  }
  [data-dialog][data-drawer] [data-dialog-container].layout-content > .spinner-container {
    @apply sticky h-screen;
  }
  [data-dialog][data-drawer] [data-dialog-closable] {
    @apply absolute top-0 right-auto -left-10 p-4 py-2 bg-white rounded-bl;
  }
  [data-dialog][data-drawer] .form-defaults h1 {
    @apply text-2xl font-semibold text-black my-10 mx-4 border-b border-gray border-b-[1px] pb-8;
  }
  [data-dialog][data-drawer] .form-defaults h1 > .button {
    @apply text-sm float-right;
  }
  .share-modal {
    @apply md:pb-16 md:pr-16;
  }
  .share-modal__list {
    @apply mt-8 grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-6;
  }
  .share-modal__list > * {
    @apply button button__lg border border-gray hover:border-tertiary shadow-[0_4px_6px_rgba(211,211,211,0.25)] text-md text-gray-2 gap-2;
  }
  .share-modal__list > * img,
  .share-modal__list > * svg {
    @apply flex-none w-6 h-6 fill-current;
  }
  .share-modal__input {
    @apply mt-8 w-full relative;
  }
  .share-modal__input #urlShareLink {
    @apply w-full truncate pr-10;
  }
  .share-modal__input > button {
    @apply absolute right-2 inset-y-2 text-secondary [&>svg]:fill-current;
  }
  .flag-modal__form {
    @apply gap-1 pt-8 pb-10;
  }
  .flag-modal__form-description {
    @apply text-xl font-semibold text-gray-2;
  }
  .flag-modal__form-reason {
    @apply text-lg text-gray-2 mt-6;
  }
  .flag-modal__form-textarea-label {
    @apply text-gray-2 mt-8;
  }
  .modal__report-container__radio {
    @apply grid grid-cols-[min-content_1fr] content-start mb-4;
  }
  .modal__report-container__radio label {
    @apply font-semibold text-lg mb-2;
  }
  .modal__report-text {
    @apply text-lg text-gray-2 font-semibold mt-4;
  }
  .modal__report fieldset label {
    @apply inline-block text-md font-normal mb-0;
  }
  .modal__report legend {
    @apply text-lg my-4 block;
  }
  .identities-modal__list {
    @apply flex flex-col gap-2 py-10;
  }
  .identities-modal__list button {
    @apply w-full border-4 border-background rounded p-2 flex items-center justify-between cursor-pointer;
  }
  .identities-modal__list button.is-selected {
    @apply border-transparent bg-success/10 [&_svg]:visible;
  }
  .identities-modal__list button:focus, .identities-modal__list button:focus-visible, .identities-modal__list button:hover {
    @apply border-tertiary outline-none;
  }
  .identities-modal__list button svg {
    @apply w-6 h-6 text-success fill-current invisible;
  }
  .authorization-modal {
    @apply pb-16 pr-16;
  }
  .authorization-modal__verification p {
    @apply text-gray-2 text-lg;
  }
  .authorization-modal__verification p + * {
    @apply mt-8;
  }
  .authorization-modal__verification li {
    @apply font-semibold;
  }
  .authorization-modal__verification-container {
    @apply mt-8 space-y-8;
  }
  .upload-modal__dropzone {
    @apply bg-background px-5 py-6 rounded max-h-[50vh] overflow-y-auto border-2 border-transparent;
  }
  .upload-modal__dropzone[hidden] + .upload-modal__dropzone:last-child {
    @apply py-14;
  }
  .upload-modal__dropzone.is-disabled label.button__secondary {
    @apply text-gray-2 bg-background-3 border border-gray-2 cursor-not-allowed;
  }
  .upload-modal__dropzone-container {
    @apply space-y-4;
  }
  .upload-modal__dropzone-placeholder {
    @apply w-full flex flex-col md:flex-row items-center justify-between gap-4 font-semibold text-gray-2 text-md [&>span]:inline-flex [&>span]:items-center [&>span]:gap-2;
  }
  .upload-modal__dropzone.is-dragover:not(.is-disabled):last-child {
    @apply border-2 border-secondary border-dashed bg-background-4 [&_*]:pointer-events-none;
  }
  .upload-modal__dropzone [data-filename] {
    @apply w-full flex flex-col gap-4;
  }
  .upload-modal__dropzone [data-filename] [data-template] {
    @apply grow flex flex-col md:flex-row items-center justify-start gap-6;
  }
  .upload-modal__dropzone [data-filename] [data-template] > *:first-child {
    @apply w-24 flex-none flex justify-center;
  }
  .upload-modal__dropzone [data-filename] [data-template] img[src="data:,"] {
    @apply hidden;
  }
  .upload-modal__dropzone [data-filename] [data-template] span {
    @apply text-gray-2 text-sm;
  }
  .upload-modal__dropzone [data-filename] [data-template=error] svg {
    @apply w-16 h-16 text-primary fill-current;
  }
  .upload-modal__dropzone [data-filename] [data-template=error] span:nth-child(2) {
    @apply text-primary font-semibold;
  }
  .upload-modal__dropzone [data-filename] [data-template=error] li {
    @apply text-gray-2 text-sm;
  }
  .upload-modal__dropzone [data-filename] [data-template=error] div:not(:first-child) {
    @apply flex flex-col gap-2 [&_label]:font-semibold;
  }
  .upload-modal__dropzone [data-filename] [data-template=titled] div:not(:first-child) {
    @apply flex flex-col md:flex-row justify-start items-stretch gap-6 md:gap-12 grow [&>div]:flex [&>div]:flex-col [&>div]:gap-2 [&>input]:mt-0 [&_label]:font-semibold;
  }
  .upload-modal__dropzone [data-filename] img {
    @apply h-full object-cover;
  }
  .upload-modal__dropzone [data-filename] button {
    @apply w-full md:w-auto md:ml-auto button button__sm button__transparent-primary;
  }
  .upload-modal__dropzone [data-filename] progress {
    @apply w-full h-1 appearance-none rounded-full overflow-hidden;
  }
  .upload-modal__dropzone [data-filename] progress::-webkit-progress-value {
    @apply bg-success;
  }
  .upload-modal__dropzone [data-filename] progress::-webkit-progress-bar {
    @apply bg-white;
  }
  .upload-modal__dropzone [data-filename] progress::-moz-progress-bar {
    @apply bg-success;
  }
  .upload-modal__dropzone [data-filename] [data-template=error] + progress::-webkit-progress-value {
    @apply bg-alert;
  }
  .upload-modal__dropzone [data-filename] [data-template=error] + progress::-moz-progress-bar {
    @apply bg-alert;
  }
  .upload-modal__dropzone [data-filename] + [data-filename] {
    @apply mt-6;
  }
  .upload-modal__dropzone [data-filename]:only-child progress {
    @apply h-4 rounded;
  }
  .upload-modal__text {
    @apply text-sm text-gray-2 mt-4 mb-8;
  }
  .upload-modal__text ul,
  .upload-modal__text ol {
    @apply list-disc pl-4;
  }
  .upload-modal__files {
    @apply mt-1 space-y-4;
    /* It does not use regular class names, because it could come from javascript also. Avoid binds among css and js */
  }
  .upload-modal__files-container {
    @apply space-y-10 [&_label]:block [&_label]:mb-4;
  }
  .upload-modal__files > * {
    @apply flex flex-col gap-2;
  }
  .upload-modal__files > * div {
    @apply w-full rounded bg-background flex items-center justify-center py-4 [&_img]:object-cover [&_img]:h-[200px];
  }
  .upload-modal__files > * span {
    @apply text-sm text-gray-2 mx-auto;
  }
  .tos-refuse {
    /* Overwrite default modal styles */
  }
  .tos-refuse [data-dialog-actions] {
    @apply flex-nowrap;
  }
  .tos-refuse a {
    @apply underline text-secondary;
  }
  .fingerprint-modal__container {
    @apply space-y-4;
  }
  .fingerprint-modal__span {
    @apply block font-semibold mb-2;
  }
  .fingerprint-modal__code {
    @apply block max-h-24 overflow-auto border border-gray-3 bg-background p-2 break-all;
  }
  .fingerprint-modal a {
    @apply text-secondary cursor-pointer;
  }
  .address {
    @apply space-y-2 text-gray-2;
  }
  .address__container {
    @apply flex items-start gap-1;
  }
  .address__container > svg {
    @apply flex-none text-gray fill-current mt-0.5;
  }
  .address__location {
    @apply text-md font-semibold;
  }
  .address__address, .address__hints {
    @apply text-sm break-words;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .statistic {
    @apply flex flex-col items-start gap-2 p-4 rounded border-[3px] border-background;
  }
  .statistic__container {
    @apply grid grid-cols-2 md:grid-cols-[repeat(auto-fit,minmax(0,120px))] gap-4;
  }
  .statistic__title {
    @apply w-full truncate text-gray-2 font-normal text-sm;
  }
  .statistic__number {
    @apply w-full text-gray-2 font-bold text-3xl;
  }
  .content-block {
    @apply flex flex-col gap-8 items-stretch py-8 md:py-16;
  }
  .content-block__title {
    @apply flex justify-start items-center gap-4 last:[&>a]:ml-auto;
  }
  .content-block__description {
    @apply text-lg prose max-w-2xl;
  }
  .content-block__description[data-component=accordion] [id*=panel][aria-hidden=true] {
    @apply block max-h-40 overflow-hidden relative first:[&_*]:mt-0 before:content-[""] before:absolute before:inset-0 before:h-full before:w-full before:bg-gradient-to-b before:from-transparent before:to-white after:content-[""] after:absolute after:inset-0 after:h-full after:w-full after:bg-gradient-to-b after:from-transparent after:to-white;
  }
  .content-block__description[data-component=accordion] [aria-expanded=false] > svg:last-of-type,
  .content-block__description[data-component=accordion] [aria-expanded=false] > span:last-of-type,
  .content-block__description[data-component=accordion] [aria-expanded=true] > span:first-of-type,
  .content-block__description[data-component=accordion] [aria-expanded=true] > svg:first-of-type {
    @apply hidden;
  }
  .content-block__description[data-component=accordion] [aria-expanded=true] > svg:last-of-type,
  .content-block__description[data-component=accordion] [aria-expanded=true] > span:last-of-type,
  .content-block__description[data-component=accordion] [aria-expanded=false] > span:first-of-type,
  .content-block__description[data-component=accordion] [aria-expanded=false] > svg:first-of-type {
    @apply block;
  }
  .content-block__span {
    @apply text-gray-2 font-semibold uppercase;
  }
  .content-block ~ .content-block {
    @apply border-t-2 border-background;
  }
  .documents__container {
    @apply divide-y divide-background [&>*]:py-4 first:[&>*]:pt-0 last:[&>*]:pb-0;
  }
  .documents__container > * {
    @apply flex items-center justify-between gap-4;
  }
  .documents__collection-trigger {
    @apply mt-8 flex gap-2 w-full md:max-w-[50%] text-lg bg-background;
  }
  .documents__collection-trigger > span {
    @apply font-normal text-gray-2;
  }
  .documents__collection-trigger > svg {
    @apply w-4 h-4 text-gray-2;
  }
  .documents__collection-trigger__icon svg {
    @apply text-gray fill-current;
  }
  .documents__collection-trigger[aria-expanded=true] {
    @apply rounded bg-background-4;
  }
  .documents__collection-trigger[aria-expanded=true] > span, .documents__collection-trigger[aria-expanded=true] .documents__collection-trigger__icon svg {
    @apply font-semibold text-secondary;
  }
  .documents__collection-trigger[aria-expanded=false] .documents__collection-trigger__icon > svg:last-of-type, .documents__collection-trigger[aria-expanded=true] .documents__collection-trigger__icon > svg:first-of-type {
    @apply hidden;
  }
  .documents__collection-trigger[aria-expanded=true] .documents__collection-trigger__icon > svg:last-of-type, .documents__collection-trigger[aria-expanded=false] .documents__collection-trigger__icon > svg:first-of-type {
    @apply block;
  }
  .documents__collection-content {
    @apply md:pl-8 md:border-l-4 border-background-4 mt-4 w-full border-t-0;
  }
  .documents__collection-content[aria-hidden=true] {
    @apply md:hidden;
  }
  .documents__collection-content .documents__container {
    @apply mt-4 pt-4 border-t border-background;
  }
  .documents__container .card__list-text {
    @apply text-sm;
  }
  [data-floating-help] {
    @apply absolute z-10 top-5 md:top-10 right-0 [&_button]:bg-background [&_button]:px-2 [&_button]:rounded-r-none [&_svg]:text-gray [&_svg]:fill-current;
  }
  [data-floating-help]:hover {
    @apply [&_svg]:text-white [&_button]:text-white [&_button]:bg-secondary [&_button]:no-underline;
  }
  .card {
    /* shared styles */
    /* shared styles */
    /* shared styles */
  }
  .card__highlight {
    @apply ring-4 ring-background hover:ring-tertiary rounded flex flex-col md:flex-row hover:cursor-pointer transition;
  }
  .card__highlight-img {
    @apply w-full md:w-1/2 aspect-video rounded-t md:rounded-t-none md:rounded-l overflow-hidden;
  }
  .card__highlight-img img {
    @apply w-full h-full object-cover;
  }
  .card__highlight-text {
    @apply w-full md:w-1/2 flex flex-col p-4 gap-y-2 md:gap-y-4 text-gray-2;
  }
  .card__highlight:hover .card__highlight-text h3 {
    @apply underline;
  }
  .card__grid {
    @apply flex flex-col ring-4 ring-background hover:ring-tertiary rounded hover:cursor-pointer transition;
  }
  .card__grid-grid {
    @apply grid gap-4 md:gap-12 md:grid-cols-2;
  }
  .card__grid-home {
    @apply grid gap-4 md:gap-12 md:grid-cols-3;
  }
  .card__grid-img {
    @apply aspect-[21/9] rounded-t overflow-hidden;
  }
  .card__grid-img img {
    @apply w-full h-full object-cover;
  }
  .card__grid-text {
    @apply grow px-4 py-3 flex flex-col gap-y-2 md:gap-y-4 text-gray-2 break-words;
  }
  .card__grid:hover .card__grid-text h3 {
    @apply underline;
  }
  .card__list {
    @apply flex flex-col md:flex-row gap-2.5 md:gap-4 px-4 rounded overflow-hidden border-l-4 border-background hover:border-tertiary focus-visible:border-tertiary focus-visible:outline-none transition;
  }
  .card__list-list {
    @apply space-y-10;
  }
  .card__list-content {
    @apply flex flex-col gap-2.5;
  }
  .card__list-title {
    @apply h4 text-secondary;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .card__list-image {
    @apply w-full md:w-1/5 flex-none rounded overflow-hidden aspect-[1.5] md:-ml-[calc(1rem+4px)];
  }
  .card__list-image img {
    @apply w-full h-full object-cover;
  }
  .card__list-text {
    @apply text-gray-2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .card__list + .card__list {
    @apply mt-6;
  }
  .card__list:focus .card__list-title, .card__list:hover .card__list-title {
    @apply underline;
  }
  .card__search {
    @apply flex flex-col gap-2 items-start w-full;
  }
  .card__search-title {
    @apply text-secondary;
  }
  .card__search + .card__search {
    @apply mt-6;
  }
  .card__search:focus .card__search-title, .card__search:hover .card__search-title {
    @apply underline;
  }
  .card__calendar {
    @apply w-14 flex flex-col justify-start rounded overflow-hidden bg-background text-center;
    /* overwrite defaults */
  }
  .card__calendar-list__reset {
    @apply border-0 px-0 flex-row items-start first:[&>*]:flex-none;
  }
  .card__calendar-month {
    @apply bg-primary uppercase text-white text-xs rounded-t md:rounded-tr-none;
  }
  .card__calendar-day {
    @apply text-black text-2xl font-bold;
  }
  .card__calendar-year {
    @apply text-black text-xs;
  }
  .card__calendar-month, .card__calendar-day, .card__calendar-year {
    @apply inline-flex items-center justify-evenly empty:[&>div]:hidden;
  }
  .card__search-metadata, .card__list-metadata {
    @apply mt-auto inline-flex flex-wrap gap-x-4 md:gap-0;
  }
  .card__search-metadata > div, .card__list-metadata > div {
    @apply inline-flex items-center gap-1 px-0 md:px-6 border-gray-3 border-0 md:border-r first:pl-0 last:pr-0 last:border-r-0 text-sm text-gray-2;
  }
  .card__search-metadata > div > svg, .card__list-metadata > div > svg {
    @apply w-3.5 h-3.5 fill-current text-gray;
  }
  .card__search-metadata [data-author] + [data-author], .card__list-metadata [data-author] + [data-author] {
    @apply -ml-4;
  }
  .card__highlight-metadata, .card__grid-metadata {
    @apply mt-auto flex items-center justify-between flex-wrap text-sm text-gray-2 [&>*]:flex [&>*]:items-center [&>*]:gap-1 first:[&>*]:flex-none;
  }
  .card__highlight-metadata svg, .card__grid-metadata svg {
    @apply flex-none text-gray fill-current;
  }
  .card__highlight-loader, .card__grid-loader {
    --color: var(--primary);
    --inner: theme("colors.background.DEFAULT");
    --outer: theme("colors.gray.3");
    @apply w-3.5 h-3.5 mx-auto grid place-items-center rounded-full overflow-hidden bg-[radial-gradient(farthest-side,var(--inner)_60%,transparent_0_100%),conic-gradient(var(--color)_calc(var(--value)*100%),var(--outer)_0)];
  }
  .card__placeholder-l {
    @apply top-[calc(100%-10rem)] left-[calc(100%-11.5rem)] absolute w-48 h-44 z-10 fill-primary;
  }
  .card__placeholder-g {
    @apply w-full h-full fill-primary;
  }
  [data-floating-help] {
    @apply absolute z-10 top-5 md:top-10 right-0 [&_button]:bg-background [&_button]:px-2 [&_button]:rounded-r-none [&_svg]:text-gray [&_svg]:fill-current;
  }
  [data-floating-help]:hover {
    @apply [&_svg]:text-white [&_button]:text-white [&_button]:bg-secondary [&_button]:no-underline;
  }
  .metric {
    @apply flex flex-col rounded border-4 border-background p-4;
  }
  .metric__container {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10;
  }
  .metric h3 {
    @apply font-semibold text-lg text-gray-2;
  }
  .metric p {
    @apply font-normal text-sm text-gray-2;
  }
  .metric a {
    @apply mt-auto self-end font-normal text-sm text-secondary;
  }
  .metric svg {
    @apply overflow-visible rounded bg-background my-8;
  }
  .metric .area {
    @apply fill-primary/5;
  }
  .metric .line {
    @apply stroke-primary/20 stroke-2;
    fill: none;
  }
  .metric .circle {
    @apply fill-primary/20;
  }
  .metric .tick {
    @apply opacity-25;
  }
  .metric .dashed {
    stroke-dasharray: 1, 3;
  }
  .metric .sum {
    @apply font-bold text-3xl;
  }
  [id$=metric-tooltip] {
    @apply absolute overflow-visible translate-x-[-50%] shadow-[0_0_20px_0] shadow-gray bg-white;
  }
  [id$=metric-tooltip] > * {
    @apply p-2 text-sm relative;
  }
  [id$=metric-tooltip] > *::after, [id$=metric-tooltip] > *::before {
    @apply top-full left-1/2 content-[""] h-0 w-0 absolute pointer-events-none border-transparent;
  }
  [id$=metric-tooltip] > *::after {
    @apply top-[calc(100%_-_1px)] -ml-[10px] border-[10px] border-t-white;
  }
  [id$=metric-tooltip] > *::before {
    @apply -ml-[11px] border-[11px];
  }
  [id*=dropdown-menu].order-by {
    @apply md:flex-row justify-start md:justify-end md:gap-x-4;
  }
  [id*=dropdown-menu].order-by .button {
    @apply justify-end items-end;
  }
  [data-target*=dropdown].order-by__button {
    @apply justify-end;
  }
  [data-target*=dropdown].order-by__button > svg {
    @apply h-4 w-4 text-gray;
  }
  .photo {
    @apply cursor-pointer text-left focus-visible:outline-none;
  }
  .photo__container {
    @apply grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-10 md:gap-12 place-items-start;
  }
  .photo__img {
    @apply border-4 border-background rounded overflow-hidden aspect-[4/3];
  }
  .photo__img img {
    @apply w-full h-full object-cover;
  }
  .photo__title {
    @apply mt-4 text-black text-lg font-semibold;
  }
  .photo__description {
    @apply mt-2 text-gray-2 text-sm;
  }
  .photo:hover .photo__img, .photo:focus .photo__img {
    @apply border-tertiary;
  }
  .photo:hover .photo__title, .photo:focus .photo__title {
    @apply underline;
  }
  .photo-modal {
    @apply pb-16 pr-16;
  }
  .photo-modal__img {
    @apply mt-8 rounded overflow-hidden;
  }
  .photo-modal__img img {
    @apply w-full h-full object-cover;
  }
  .photo-modal__title {
    @apply mt-8 font-semibold text-lg text-gray-2;
  }
  .photo-modal__description {
    @apply text-gray-2 text-md;
  }
  .endorsers-list {
    @apply space-y-3 opacity-0 -translate-y-full invisible transition-all absolute;
  }
  .endorsers-list__text {
    @apply text-md text-gray-2 ml-4 leading-normal;
  }
  .endorsers-list__text a {
    @apply text-md;
  }
  .endorsers-list__grid {
    @apply flex flex-wrap gap-y-3;
  }
  .endorsers-list__avatar {
    @apply rounded-full overflow-hidden border border-white inline-grid w-6 h-6;
  }
  .endorsers-list__button {
    @apply block ml-auto text-right text-xs text-secondary cursor-pointer;
  }
  .endorsers-list__container {
    @apply w-full flex justify-start -space-x-4 p-0 gap-2;
  }
  .endorsers-list__container > span {
    @apply inline-grid;
  }
  .endorsers-list__modal .list-item__author {
    @apply flex w-full justify-between;
  }
  .endorsers-list__modal .list-item__author .author__avatar-container {
    @apply w-8 h-8;
  }
  .endorsers-list__modal .list-item__author .button {
    @apply [&>svg]:hidden;
  }
  .endorsers-list__modal .list-item__author + .list-item__author {
    --vertical-spacing: 1rem;
    @apply mt-[var(--vertical-spacing)];
  }
  .activity {
    @apply grid grid-cols-[1fr_max-content] md:grid-cols-[calc(100%/6)_1fr_max-content] items-center md:items-start gap-2 md:gap-12 py-4 first:pt-0 last:pb-0;
  }
  .activity__container {
    @apply divide-y divide-gray-3;
  }
  .activity__time {
    @apply w-full flex-none text-sm text-gray-2;
  }
  .activity__content {
    @apply col-span-2 md:col-span-1 order-first md:order-none space-y-2;
  }
  .activity__content > span:first-child span {
    @apply text-gray-2 text-sm;
  }
  .activity__content > span:first-child span svg {
    @apply text-gray fill-current;
  }
  .activity__content > span:first-child svg {
    @apply inline-block;
  }
  .activity__content > span:first-child a {
    @apply text-secondary font-semibold hover:underline;
  }
  .activity__content > span:last-child:not(:only-child) {
    @apply flex items-center gap-1 text-gray-2 text-sm;
  }
  .activity__content > span:last-child:not(:only-child) svg {
    @apply w-3.5 h-3.5 text-gray fill-current;
  }
  .activity__content > span:last-child:not(:only-child) a {
    @apply underline;
  }
  .activity__author {
    @apply ml-auto flex-none;
  }
  [role=tooltip] {
    @apply absolute bg-black z-10 px-4 py-2 w-max max-w-xs rounded text-left text-white;
    /*
      NOTE: the calculated value is the sum of the arrow offset position plus the half of the arrow size:
      - offset position: 20%
      - arrow size: 16px
    */
    --arrow-offset: 20%;
    --arrow-size: 16px;
    --arrow-visible-size: var(--arrow-size) * 0.5;
    --arrow-margin: var(--arrow-visible-size) * 1.4142135623730951;
  }
  [role=tooltip] > * {
    @apply relative z-20;
  }
  [role=tooltip][aria-hidden=false] {
    animation-name: fadeIn;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }
  [role=tooltip][aria-hidden=true] {
    animation-name: fadeOut;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
  [role=tooltip].top {
    @apply -translate-x-[calc(100%-var(--arrow-offset))] -translate-y-[calc(100%+var(--arrow-margin))] before:content-[""] before:absolute before:-z-10 before:right-[calc(var(--arrow-offset)-var(--arrow-visible-size))] before:-bottom-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-br;
  }
  [role=tooltip].right {
    @apply translate-x-[calc(var(--arrow-margin))] -translate-y-[calc(var(--arrow-offset)+var(--arrow-visible-size))] before:content-[""] before:absolute before:-z-10 before:top-[var(--arrow-offset)] before:-left-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-bl;
  }
  [role=tooltip].bottom {
    @apply -translate-x-[calc(100%-var(--arrow-offset))] translate-y-[calc(var(--arrow-margin))] before:content-[""] before:absolute before:-z-10 before:right-[calc(var(--arrow-offset)-var(--arrow-visible-size))] before:-top-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-tl;
  }
  [role=tooltip].left {
    @apply -translate-x-[calc(100%+var(--arrow-margin))] -translate-y-[calc(var(--arrow-offset)+var(--arrow-visible-size))] before:content-[""] before:absolute before:-z-10 before:top-[var(--arrow-offset)] before:-right-[var(--arrow-visible-size)] before:w-[var(--arrow-size)] before:h-[var(--arrow-size)] before:rotate-45 before:bg-black before:rounded-tr;
  }
  .author {
    @apply flex items-center [&>*:not(:first-child)]:pl-5 [&>*:not(:last-child)]:pr-6 divide-gray-3 divide-x text-sm text-gray-2;
  }
  .author__container {
    @apply flex items-center gap-2.5 first:[&>*]:flex-none;
  }
  .author__container > :last-child {
    @apply flex flex-col [&>*]:grow;
  }
  .author__avatar {
    @apply w-full h-full object-cover;
  }
  .author__avatar-container {
    @apply rounded-full overflow-hidden inline-block w-6 h-6 align-top;
  }
  .author__avatar-counter {
    @apply -ml-4 rounded-full overflow-hidden inline-grid w-6 h-6 bg-background place-items-center text-secondary text-xs font-semibold;
  }
  .author__container.is-compact .author__avatar-container {
    @apply w-8 h-8;
  }
  .author__container.is-compact .author__name {
    @apply text-secondary font-semibold;
  }
  .author__metadata {
    @apply flex items-center gap-1 text-gray-2 text-sm;
  }
  .author__metadata > svg {
    @apply text-gray fill-current flex-none;
  }
  .author__tooltip {
    @apply grid grid-cols-[1fr_max-content] gap-6 odd:[&>*]:place-self-start even:[&>*]:place-self-end z-50;
    /* overwrite default styles */
  }
  .author__tooltip[role=tooltip] {
    @apply max-w-max;
  }
  .author__tooltip-links {
    @apply flex flex-col items-end justify-evenly h-full whitespace-nowrap text-sm hover:[&>*]:underline;
  }
  .author__tooltip .author__avatar-container {
    @apply w-10 h-10 border-2 border-white hover:border-tertiary;
  }
  .author__tooltip .author__name {
    @apply text-white text-lg font-bold hover:underline;
  }
  .author__coauthors {
    @apply flex flex-wrap items-center gap-2;
  }
  .author__coauthors[aria-hidden=false] + [id$=trigger] span:last-child, .author__coauthors[aria-hidden=true] + [id$=trigger] span:first-child {
    @apply first-letter:lowercase block;
  }
  .author__coauthors[aria-hidden=false] + [id$=trigger] span:first-child, .author__coauthors[aria-hidden=true] + [id$=trigger] span:last-child {
    @apply hidden;
  }
  [id*=dropdown-menu].order-by {
    @apply md:flex-row justify-start md:justify-end md:gap-x-4;
  }
  [id*=dropdown-menu].order-by .button {
    @apply justify-end items-end;
  }
  [data-target*=dropdown].order-by__button {
    @apply justify-end;
  }
  [data-target*=dropdown].order-by__button > svg {
    @apply h-4 w-4 text-gray;
  }
  .wizard-steps {
    @apply flex items-stretch my-10;
  }
  .wizard-steps > * {
    @apply w-1/4 relative text-center text-sm text-gray-2 before:content-[""] before:absolute before:-top-3 before:-left-1/2 before:w-full first:before:w-0 before:h-0.5 before:bg-success before:-translate-y-1/2 after:z-10 after:content-[""] after:absolute after:-top-1 after:left-1/2 after:w-4 after:h-4 after:bg-success after:border after:border-white after:rounded-full after:-translate-y-full after:-translate-x-1/2;
  }
  .wizard-steps > *[aria-current] {
    @apply font-semibold;
  }
  .wizard-steps > *[aria-current] ~ * {
    @apply before:bg-gray-3 after:bg-gray-3;
  }
  .progress-bar {
    @apply w-full h-1.5 mt-1 rounded overflow-hidden bg-gray translate-x-0 [&>*]:bg-success [&>*]:h-full;
  }
  .progress-bar > * {
    animation-name: slideInLeft;
    animation-duration: 500ms;
  }
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
  }
  .progress-bar__container {
    @apply flex flex-col items-center;
  }
  .progress-bar__number {
    @apply text-2xl md:text-4xl md:[&>*:only-child]:text-5xl text-gray-2 font-bold;
  }
  .progress-bar__number > *:last-child:not(:only-child) {
    @apply before:content-["_/_"] before:text-gray before:font-normal;
  }
  .progress-bar__units {
    @apply text-sm text-gray-2 first-letter:uppercase;
  }
  .progress-bar__sm {
    @apply w-16;
  }
  .progress-bar__sm .progress-bar__number {
    @apply text-gray-2 font-normal text-sm;
  }
  .progress-bar__sm .progress-bar__units {
    @apply hidden;
  }
  .spinner-container {
    @apply relative cursor-wait;
  }
  .spinner-container::after {
    @apply content-[""] absolute inset-0 w-full h-full bg-white/80 z-10;
  }
  .spinner-container::before {
    @apply content-[""] sticky top-[50vh] ml-[50%] md:ml-[calc(50%-4rem)] block w-6 h-6 -mt-6 rounded-full animate-spin border-4 border-l-background border-y-background border-r-secondary z-20;
  }
  .toggle__switch-trigger {
    @apply flex justify-between items-center gap-2;
  }
  .toggle__switch-trigger > :last-child {
    @apply grow flex items-center gap-2;
  }
  .toggle__switch-trigger > :first-child {
    @apply flex-none;
  }
  .toggle__switch-trigger-title {
    @apply h4 text-secondary;
  }
  .toggle__switch-trigger-snippet {
    @apply text-gray-2 mt-2;
  }
  .toggle__switch-trigger-text {
    @apply ml-12 font-normal text-md;
  }
  .toggle__switch-trigger-arrow {
    @apply w-8 h-8 text-secondary fill-current first:block last:hidden;
  }
  [role=button][aria-expanded=true] .toggle__switch-trigger-arrow {
    @apply first:hidden last:block;
  }
  .toggle__switch-toggle {
    @apply relative inline-block w-full rounded-full cursor-pointer;
  }
  .toggle__switch-toggle > :first-child {
    @apply h-6 relative;
  }
  .toggle__switch-toggle input {
    @apply opacity-0 w-0 h-0;
  }
  .toggle__switch-toggle-content {
    @apply absolute top-0 left-0 right-0 bottom-0 bg-alert/10 rounded-full before:content-[""] before:absolute before:w-5 before:h-5 before:bottom-0.5 before:left-0.5 before:rounded-full before:bg-alert before:transition before:duration-300 w-14;
  }
  input:focus ~ .toggle__switch-toggle-content {
    @apply outline outline-alert outline-offset-2 transition-none;
  }
  input:focus:checked ~ .toggle__switch-toggle-content {
    @apply outline-success;
  }
  input:checked ~ .toggle__switch-toggle-content {
    @apply bg-success before:bg-white before:translate-x-8;
  }
  input:disabled ~ .toggle__switch-toggle-content {
    @apply bg-background-3 cursor-not-allowed before:bg-gray before:border before:border-gray;
  }
  .toggle__switch-toggle-icon {
    @apply absolute top-1/2 -translate-y-1/2 w-4 h-4 fill-current first-of-type:text-white last-of-type:text-alert first-of-type:hidden last-of-type:block first-of-type:left-2 last-of-type:left-9 w-auto;
  }
  input:checked ~ .toggle__switch-toggle-icon {
    @apply first-of-type:block last-of-type:hidden;
  }
  input:disabled ~ .toggle__switch-toggle-icon {
    @apply text-gray cursor-not-allowed;
  }
  .hero {
    @apply h-[60vh] md:h-[65vh] container px-4 md:px-24 flex flex-col justify-center items-center gap-8 md:gap-16;
  }
  .hero__container {
    /* stylelint-disable-next-line number-leading-zero */
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 21.88%, rgba(0, 0, 0, 0.46421) 60.94%, rgba(0, 0, 0, 0.105) 100%), var(--hero-image);
    @apply bg-center bg-cover;
  }
  .hero__title {
    @apply text-center text-white text-2xl md:text-5xl font-bold;
  }
  .actions__secondary {
    @apply flex flex-row md:flex-col justify-center md:gap-y-4 gap-x-10 md:gap-x-0;
  }
  .actions__secondary .button {
    @apply justify-start;
  }
  .emoji-picker__popupContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
  }
  .emoji-picker__popupContainer .emoji-picker__closeButton {
    position: absolute;
    border: none;
    z-index: 1;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 4px;
    align-self: flex-end;
    transform: translate(50%, -50%);
    background: #999;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .emoji-picker__popupContainer .emoji-picker__closeButton:hover {
    background: #4f46e5;
  }
  .emoji-picker__popupContainer .emoji-picker__closeButton svg {
    fill: white;
    width: 1.25rem;
    height: 1.25rem;
  }
  .success-image {
    --success: #28a745;
    width: 100px;
    height: 100px;
    margin: auto;
  }
  .success-image .animation-success__checkmark {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: var(--success);
    stroke-miterlimit: 10;
    box-shadow: inset 0 0 0 #4bb71b;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.09s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
  }
  .success-image .animation-success__checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 5;
    stroke-miterlimit: 10;
    stroke: var(--success);
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .success-image .animation-success__checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0 0 0 30 var(--success);
    }
  }
}
.sc-wc-datepicker-h,
.sc-wc-datepicker-h *,
.sc-wc-datepicker-h *::before,
.sc-wc-datepicker-h *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sc-wc-datepicker-h {
  display: inline-flex;
}

.wc-datepicker {
  display: block;
  width: min-content;
  min-width: 17rem;
  max-width: 24rem;
  border: 0.0625rem solid #f2f3f5;
  color: #111;
  background-color: #fff;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.wc-datepicker--disabled *:disabled {
  opacity: 0.5;
  cursor: default;
}

.wc-datepicker--disabled .wc-datepicker__weekday {
  color: #767676;
}

.wc-datepicker--disabled .wc-datepicker__date:focus > * {
  outline: none;
}

.wc-datepicker--disabled .wc-datepicker__date {
  cursor: default;
  opacity: 0.5;
}

.wc-datepicker--disabled .wc-datepicker__date:hover > * {
  background-color: transparent;
}

.wc-datepicker--disabled .wc-datepicker__date--in-range:hover > * {
  background-color: #f2f3f5;
}

.wc-datepicker--disabled .wc-datepicker__date--selected:hover > * {
  color: #fff;
  background-color: #00f;
}

.wc-datepicker__header {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.5rem;
}

.wc-datepicker__current-month {
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
}

.wc-datepicker__month-select,
.wc-datepicker__year-select {
  display: flex;
  height: 2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: #111;
  background: #f2f3f5;
  font: inherit;
  line-height: 1.125;
}

.wc-datepicker__month-select {
  flex-grow: 1;
  cursor: pointer;
  appearance: none;
}

.wc-datepicker__year-select {
  max-width: 5rem;
}

.wc-datepicker__previous-month-button,
.wc-datepicker__next-month-button,
.wc-datepicker__previous-year-button,
.wc-datepicker__next-year-button {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  padding: 0.375rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: #111;
  background-color: transparent;
  background-color: #f2f3f5;
  cursor: pointer;
}

.wc-datepicker__body {
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
}

.wc-datepicker__calendar {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.wc-datepicker__weekday > span {
  display: flex;
  padding: 0.125rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  aspect-ratio: 1;
}

.wc-datepicker__date {
  padding: 0.125rem;
  text-align: center;
  cursor: pointer;
}

.wc-datepicker__date:focus {
  outline: none;
}

.wc-datepicker__date:focus > * {
  outline: 0.125rem solid #00f;
}

.wc-datepicker__date:hover > * {
  background-color: #f2f3f5;
}

.wc-datepicker__date > * {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  aspect-ratio: 1;
}

.wc-datepicker__date--today > * {
  font-weight: 700;
  font-style: normal;
}

.wc-datepicker__date--in-range > * {
  background-color: #f2f3f5;
}

.wc-datepicker__date--selected > *,
.wc-datepicker__date--selected:hover > * {
  color: #fff;
  background-color: #00f;
  font-weight: 500;
}

.wc-datepicker__date--disabled {
  color: #767676;
  cursor: default;
}

.wc-datepicker__date--disabled:not(.wc-datepicker__date--selected):not(.wc-datepicker__date--in-range):hover > * {
  background-color: transparent;
}

.wc-datepicker__date--disabled.wc-datepicker__date--in-range:not(.wc-datepicker__date--selected):not(.wc-datepicker__date--in-range) > * {
  background-color: transparent;
}

.wc-datepicker__footer {
  display: flex;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  align-items: center;
  gap: 0.5rem;
}

.wc-datepicker__clear-button,
.wc-datepicker__today-button {
  display: inline-flex;
  height: 2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.25rem;
  color: #111;
  background-color: #f2f3f5;
  font: inherit;
  line-height: 1;
  cursor: pointer;
}

.sc-wc-datepicker-h {
  @apply absolute w-80 h-96 left-0;
}

.wc-datepicker {
  @apply absolute w-80 h-96 z-20 text-sm;
}
.wc-datepicker__header {
  @apply px-0 py-0 pb-0 gap-0 h-14;
}
.wc-datepicker__month-select, .wc-datepicker__year-select {
  @apply h-10 mb-4 leading-normal;
}
.wc-datepicker__month-select {
  @apply pr-3;
}
.wc-datepicker__year-select {
  @apply max-w-fit;
}
.wc-datepicker__previous-month-button, .wc-datepicker__next-month-button, .wc-datepicker__previous-year-button, .wc-datepicker__next-year-button {
  @apply h-10 text-secondary bg-transparent;
}
.wc-datepicker__date:focus > * {
  @apply outline-1 outline-gray;
}
.wc-datepicker__date--selected > * {
  @apply bg-secondary border-solid border-2 border-gray;
}
.wc-datepicker__date--selected:hover > * {
  @apply bg-secondary;
}

.datepicker__container {
  @apply absolute w-80 h-96;
}
.datepicker__datepicker-row {
  @apply flex gap-4;
}
.datepicker__time-row {
  @apply flex top-6 absolute w-full;
}
.datepicker__date-column, .datepicker__time-column {
  @apply flex-1 w-1/2 relative;
}
.datepicker__date-column > input, .datepicker__time-column > input {
  @apply h-9;
}
.datepicker__hour-column, .datepicker__minute-column {
  @apply flex-1 h-20 relative my-2 mx-8 rounded border border-solid border-gray;
}
.datepicker__period-column {
  @apply w-28 h-8 top-1 right-10 my-2 mx-1 absolute;
}
.datepicker__calendar-button {
  @apply h-8 rounded absolute top-4 mt-0.5 fill-gray-2 leading-3 right-2;
}
.datepicker__clock-button {
  @apply h-8 rounded absolute top-4 mt-0.5 fill-gray-2 leading-3 right-2;
}
.datepicker__time-frame {
  @apply w-52 max-w-lg h-40 bg-white absolute border border-solid border-[#f2f3f5] z-20;
}
.datepicker__hour-picker, .datepicker__minute-picker {
  @apply w-1/2 left-2.5 h-8 text-center text-sm absolute top-6;
}
.datepicker__hour-picker:hover, .datepicker__minute-picker:hover {
  @apply cursor-default;
}
.datepicker__hour-picker:focus-visible, .datepicker__minute-picker:focus-visible {
  @apply outline-0;
}
.datepicker__close-calendar {
  @apply absolute z-30 bottom-2 right-1;
}
.datepicker__pick-calendar {
  @apply absolute z-30 bottom-2 right-16 mr-1;
}
.datepicker__pick-calendar:disabled {
  @apply bg-gray;
}
.datepicker__close-clock {
  @apply absolute z-30 bottom-2 right-1;
}
.datepicker__reset-clock {
  @apply absolute z-30 bottom-2 left-2;
}
.datepicker__select-clock {
  @apply absolute z-30 bottom-2 right-16 mr-1;
}
.datepicker__hour-up {
  @apply absolute rounded-t w-full h-6;
}
.datepicker__hour-up:hover {
  @apply bg-gray-3;
}
.datepicker__hour-down {
  @apply absolute rounded-b w-full h-6 bottom-0;
}
.datepicker__hour-down:hover {
  @apply bg-gray-3;
}
.datepicker__minute-up {
  @apply absolute rounded-t w-full h-6;
}
.datepicker__minute-up:hover {
  @apply bg-gray-3;
}
.datepicker__minute-down {
  @apply absolute rounded-b w-full h-6 bottom-0;
}
.datepicker__minute-down:hover {
  @apply bg-gray-3;
}
.datepicker__period-am {
  @apply ml-1 accent-secondary bg-secondary;
}
.datepicker__period-pm {
  @apply ml-1;
}
.datepicker__label-row {
  @apply absolute flex top-2 w-full text-sm font-semibold;
}
.datepicker__hour-label-container {
  @apply flex-1 text-center;
}
.datepicker__minute-label-container {
  @apply flex-1 text-center;
}
.datepicker__help-text-container {
  @apply w-full h-10 relative;
}
.datepicker__help-date {
  @apply w-1/2 relative inline-block;
}
.datepicker__help-time {
  @apply w-1/2 relative inline-block left-2;
}

input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

header .admin-bar {
  @apply container flex flex-wrap items-center justify-start gap-y-2 gap-x-4;
}
header .admin-bar__container {
  @apply bg-tertiary py-2;
}
header .admin-bar__title {
  @apply hidden md:block text-sm font-bold text-black;
}
header .admin-bar__button {
  @apply button button__xs bg-white/[0.15] text-black font-normal;
}
header .admin-bar__title + .admin-bar__button + .admin-bar__button {
  @apply ml-0 md:ml-auto;
}
header #flash-messages-container {
  @apply mt-28 md:mt-0;
}
header.with-admin-bar #flash-messages-container {
  @apply mt-40 md:mt-0;
}
header.with-admin-bar #menu-bar-container {
  @apply mt-[140px] md:mt-0;
}
header #sticky-header-container {
  @apply fixed w-full top-0 shadow-lg z-40 bg-white md:relative transition-top duration-300;
}
header .main-bar {
  @apply container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4 items-center py-5 h-24;
  /* overwrite default dropdown styles */
}
header .main-bar__container {
  @apply relative w-full;
}
header .main-bar__logo {
  @apply col-span-3 xl:col-span-2 prose prose-img:max-h-16 prose-img:my-0;
}
header .main-bar__logo-desktop {
  @apply hidden lg:block;
}
header .main-bar__logo-mobile {
  @apply lg:hidden;
}
header .main-bar__search {
  @apply hidden md:block col-span-2 col-start-5 xl:col-start-4;
}
header .main-bar__search form {
  @apply block relative rounded text-md bg-background;
}
header .main-bar__search input[type=text] {
  @apply block bg-transparent w-full px-4 py-1.5;
}
header .main-bar__search button[type=submit] {
  @apply absolute ltr:right-2 rtl:left-2 inset-y-2 text-secondary;
}
header .main-bar__menu-mobile {
  @apply lg:hidden flex flex-row-reverse items-center;
}
header .main-bar__links-desktop,
header .main-bar > *:last-child {
  @apply col-span-1 md:col-start-8 lg:col-start-8 lg:col-span-5 justify-self-end;
}
header .main-bar__links-desktop {
  @apply hidden lg:flex items-center justify-between text-center divide-x-2 divide-gray-3 ml-auto [&>*]:px-4 xl:[&>*]:px-6 first:[&>*]:pl-0 last:[&>*]:pr-0;
  /* overwrite default dropdown styles */
}
header .main-bar__links-desktop__item {
  @apply flex flex-col items-center text-secondary px-2 py-1 rounded hover:underline hover:bg-background;
}
header .main-bar__links-desktop__item-wrapper {
  @apply flex gap-x-4 xl:gap-x-6;
}
header .main-bar__links-desktop__item-account {
  @apply w-6 h-6 flex items-center justify-center bg-secondary text-white rounded-full text-xs font-bold mr-2;
}
header .main-bar__links-desktop [data-target*=dropdown] > span:only-of-type {
  @apply gap-0;
}
header .main-bar__links-desktop svg {
  @apply w-5 h-5 fill-current;
}
header .main-bar__links-desktop svg + span {
  @apply text-sm first-letter:uppercase;
}
header .main-bar__links-mobile {
  @apply fixed bottom-0 left-0 z-40 bg-white w-full px-4 py-3 flex justify-between text-secondary shadow-[0_-4px_6px_rgba(198,198,198,0.25)];
}
header .main-bar__links-mobile__trigger {
  @apply flex flex-col items-center text-secondary cursor-pointer pl-4 border-l border-gray-3;
}
header .main-bar__links-mobile__trigger svg {
  @apply w-6 h-6 fill-current;
}
header .main-bar__links-mobile__trigger span {
  @apply text-sm first-letter:uppercase;
}
header .main-bar__links-mobile__dropdown {
  @apply absolute top-full left-0 z-30 bg-white;
}
header .main-bar__links-mobile__dropdown-before {
  @apply before:block before:absolute before:-top-10 before:right-12 before:content-[""] before:bg-white before:w-24 before:h-12 before:-mt-8;
}
header .main-bar__links-mobile__account {
  @apply fixed top-0 left-0 w-full h-full z-30 bg-white;
}
header .main-bar__links-mobile__account ul {
  @apply my-6 divide-y divide-gray-3;
}
header .main-bar__links-mobile__account li {
  @apply py-4;
}
header .main-bar__links-mobile__account .dropdown__item {
  @apply text-md;
}
header .main-bar__links-mobile__account .main-bar {
  @apply border-b border-gray;
}
header .main-bar__links-mobile__account .main-bar__avatar {
  @apply w-12 h-12;
}
header .main-bar__links-mobile__account .main-bar__avatar-name {
  @apply w-12 h-12 text-2xl;
}
header .main-bar__links-mobile__account .account-container {
  @apply before:block before:absolute before:top-16 before:right-14 before:content-[""] before:bg-white before:w-2 before:h-12 before:-mt-8;
}
header .main-bar__links-mobile__item {
  @apply relative;
}
header .main-bar__links-mobile__search {
  @apply fixed top-0 left-0 w-full h-full z-30 bg-white;
}
header .main-bar__links-mobile__login {
  @apply w-20 h-full flex flex-row items-center relative text-secondary gap-1;
}
header .main-bar__links-mobile__login svg {
  @apply w-8 h-6 fill-current;
}
header .main-bar__links-mobile__login svg + span {
  @apply text-sm first-letter:uppercase pr-4 w-16;
}
header .main-bar__dropdown {
  @apply divide-y divide-gray-3 z-20 w-64;
}
header .main-bar__dropdown-container {
  @apply relative cursor-pointer;
}
header .main-bar__dropdown-notification {
  @apply w-2.5 h-2.5 rounded-full bg-primary;
}
header .main-bar__dropdown-trigger {
  @apply flex flex-col items-center text-secondary relative px-2 py-1;
}
header .main-bar__notification {
  @apply w-2.5 h-2.5 rounded-full block absolute -top-0.5 right-1.5 bg-primary;
}
header .main-bar__avatar {
  @apply block w-6 h-6 rounded-full border border-secondary overflow-hidden mr-2;
}
header .main-bar__avatar span {
  @apply w-full h-full rounded-full overflow-hidden;
}
header .main-bar__avatar img {
  @apply object-cover w-full h-full;
}
header .main-bar__avatar-name {
  @apply w-6 h-6 flex items-center justify-center bg-secondary text-white rounded-full text-xs font-bold mr-2;
}
header .main-bar [id*=dropdown-menu] {
  @apply py-0 mx-0 w-full;
}
header .main-bar [id*=dropdown-menu][aria-hidden=true] {
  @apply md:hidden;
}
header .menu-bar {
  @apply container h-full flex justify-between items-center lg:relative last-of-type:[&>svg]:hidden;
}
header .menu-bar__container {
  @apply bg-primary relative h-14;
}
header .menu-bar__breadcrumb-desktop {
  @apply hidden lg:flex justify-between items-center gap-2.5 [&>*]:text-lg [&>*]:text-white;
  /* overwrite default dropdown styles */
}
header .menu-bar__breadcrumb-desktop__dropdown-trigger {
  @apply flex rounded px-2 py-1 z-20;
}
header .menu-bar__breadcrumb-desktop__dropdown-trigger:hover {
  @apply z-10 relative before:content-[""] before:absolute before:w-[calc(100%+3rem+1px)] before:min-w-[10rem] before:h-40 before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/4 before:-z-10;
}
header .menu-bar__breadcrumb-desktop__dropdown-trigger svg {
  @apply w-6 h-6 fill-current;
}
header .menu-bar__breadcrumb-desktop__dropdown-wrapper {
  @apply flex items-center cursor-pointer rounded hover:backdrop-brightness-75 focus:backdrop-brightness-75 focus:outline-none;
}
header .menu-bar__breadcrumb-desktop__dropdown-content {
  @apply absolute z-20 top-full cursor-auto;
}
header .menu-bar__breadcrumb-desktop__dropdown-content-secondary {
  @apply absolute z-20 top-[calc(100%+12px)];
}
header .menu-bar__breadcrumb-desktop [id*=dropdown-menu] {
  @apply cursor-auto;
}
header .menu-bar__breadcrumb-desktop [id*=dropdown-menu][aria-hidden=true].no-animation {
  @apply opacity-0 invisible;
}
header .menu-bar__breadcrumb-desktop [id*=dropdown-menu][aria-hidden=true]:not(.no-animation) {
  animation-name: fadeOut;
  animation-duration: 300ms;
  animation-delay: 300ms;
  animation-fill-mode: forwards;
}
header .menu-bar__breadcrumb-desktop [id*=dropdown-menu][aria-hidden=true]:not(.no-animation):hover {
  animation: none;
}
header .menu-bar__breadcrumb-desktop [id*=dropdown-menu][aria-hidden=false] {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
header .menu-bar__breadcrumb-desktop [data-target*=dropdown] {
  @apply w-auto p-0 flex items-center gap-0 last-of-type:[&>svg]:block;
}
header .menu-bar__breadcrumb-desktop [data-target*=dropdown] > svg {
  @apply w-5 h-5 text-white;
}
header .menu-bar__breadcrumb-mobile {
  @apply block lg:hidden w-full z-20;
  /* overwrite default dropdown styles */
}
header .menu-bar__breadcrumb-mobile__dropdown-trigger {
  @apply flex items-center justify-between text-white;
}
header .menu-bar__breadcrumb-mobile__dropdown-trigger span {
  @apply flex flex-wrap md:flex-nowrap gap-x-2.5 overflow-hidden text-white;
}
header .menu-bar__breadcrumb-mobile__dropdown-trigger svg {
  @apply w-6 h-6 fill-current;
}
header .menu-bar__breadcrumb-mobile [id*=dropdown-menu] {
  @apply mx-0 py-0 border-0;
}
header .menu-bar__breadcrumb-mobile [id*=dropdown-menu][aria-hidden=true] {
  @apply md:hidden lg:flex;
}
header .menu-bar__breadcrumb-mobile [data-target*=dropdown] {
  @apply md:block lg:hidden w-auto last-of-type:[&>svg]:block ml-auto;
}
header .menu-bar__breadcrumb-mobile [data-target*=dropdown] > svg {
  @apply text-white;
}
header .menu-bar__actions {
  @apply hidden lg:flex justify-between items-center gap-6;
}
header .menu-bar__actions-mobile {
  @apply [&_svg]:w-4 [&_svg]:h-4 [&_span]:sr-only;
}
header .menu-bar__language-chooser {
  @apply absolute top-full left-0 bg-white rounded w-full bg-gray-5;
}
header .menu-bar__main-dropdown {
  @apply bg-white divide-y divide-gray-3 rounded-b shadow-lg text-black w-full lg:w-[1280px] h-screen md:h-auto;
}
header .menu-bar__main-dropdown__bottom, header .menu-bar__main-dropdown__top {
  @apply flex flex-col md:flex-row justify-between p-4 md:p-8 gap-x-8;
}
header .menu-bar__main-dropdown__bottom-right, header .menu-bar__main-dropdown__top-right {
  @apply hidden md:block md:w-1/2;
}
header .menu-bar__main-dropdown__bottom {
  @apply hidden md:flex;
}
header .menu-bar__main-dropdown__title {
  @apply hidden h4 md:flex md:h3;
}
header .menu-bar__main-dropdown__subtitle {
  @apply hidden text-md md:flex md:text-lg text-gray-2 mt-5;
}
header .menu-bar__main-dropdown__menu {
  @apply w-full md:w-1/2 mt-0 grid md:grid-cols-2 gap-x-6 text-secondary;
}
header .menu-bar__main-dropdown__menu > * {
  @apply py-3 md:py-3.5 border-b last:border-0 border-gray-3;
  /* since the grid has 2 columns, remove the border for these last 2 columns */
}
header .menu-bar__main-dropdown__menu > *:nth-last-child(-n+2) {
  @apply md:border-0;
}
header .menu-bar__main-dropdown__menu a {
  @apply flex items-center justify-start gap-1 font-semibold text-lg text-secondary;
}
header .menu-bar__main-dropdown__menu a span {
  @apply min-w-0 truncate;
}
header .menu-bar__main-dropdown__menu a svg {
  @apply flex-none fill-current;
}
header .menu-bar__secondary-dropdown {
  @apply absolute top-full left-0 lg:relative flex justify-between bg-white rounded-b shadow-lg text-black w-full lg:w-[1000px];
}
header .menu-bar__secondary-dropdown__left {
  @apply hidden md:block w-3/4 p-8 text-md;
}
header .menu-bar__secondary-dropdown__title {
  @apply h4 md:h3;
}
header .menu-bar__secondary-dropdown__menu {
  @apply w-full md:w-1/4 bg-primary px-4 md:px-8 pt-0 pb-3 md:py-3 divide-y divide-gray-3 text-white;
}
header .menu-bar__secondary-dropdown__menu > * {
  @apply py-3 md:py-3.5;
}
header .menu-bar__secondary-dropdown__menu a {
  @apply flex items-center justify-start gap-1 font-semibold text-lg text-white;
}
header .menu-bar__secondary-dropdown__menu a span {
  @apply min-w-0 truncate;
}
header .menu-bar__secondary-dropdown__menu a svg {
  @apply flex-none fill-current;
}
header .menu-bar__secondary-dropdown__metadata {
  @apply flex items-center text-sm space-x-6 py-8;
}
header .menu-bar__secondary-dropdown__metadata > span {
  @apply flex items-center space-x-2;
}

footer .main-footer {
  @apply bg-gray-4;
  /* overwrite default dropdown styles */
  /* overwrite default dropdown styles */
}
footer .main-footer__top {
  @apply hidden lg:flex flex-row gap-8 container py-10;
}
footer .main-footer__down {
  @apply border-t border-black flex flex-wrap items-center gap-6 container py-6 text-white;
}
footer .main-footer__language {
  @apply absolute top-full left-0 bg-white rounded w-full;
}
footer .main-footer__language-container {
  @apply relative;
}
footer .main-footer__language-trigger {
  @apply flex items-center gap-1 border border-white rounded py-1.5 px-2 cursor-pointer text-md font-semibold;
}
footer .main-footer [data-target*=dropdown] > svg {
  @apply w-4 h-4 text-white fill-current last-of-type:block last-of-type:ml-auto;
}
footer .main-footer [data-target*=dropdown][aria-expanded=true] > svg:first-of-type {
  @apply block;
}
footer .main-footer [data-target*=dropdown] > span {
  @apply block text-white;
}
footer .main-footer [id*=dropdown-menu] {
  @apply py-0 mx-0 w-full;
}
footer .main-footer [id*=dropdown-menu][aria-hidden=true] {
  @apply md:hidden;
}
footer .mini-footer {
  @apply bg-black py-5;
}
footer .mini-footer__content {
  @apply container flex justify-between items-center text-white;
}

.login__info {
  @apply text-gray-2 text-lg space-y-4 mb-10 prose-a:text-secondary prose-a:underline;
}
.login__info-required {
  @apply mb-10 text-gray-2 text-sm;
}
.login__info-separator {
  @apply border border-background;
}
.login__omniauth {
  @apply flex flex-col items-center gap-8 text-gray-2 font-semibold w-fit mx-auto mt-10;
}
.login__omniauth-button {
  @apply button button__xl border border-gray shadow-[0_4px_6px_rgba(211,211,211,0.25)] justify-start gap-8 text-lg w-full;
}
.login__omniauth-button svg {
  @apply w-6 h-6 fill-current flex-none;
}
.login__omniauth-separator {
  @apply mt-10 flex items-center justify-center gap-12 after:content-[""] before:content-[""] after:h-px before:h-px after:bg-background before:bg-background after:grow before:grow;
}
.login__omniauth__horizontal {
  @apply flex-row flex-wrap [&>a]:w-auto;
}
.login__links {
  @apply flex flex-col gap-4 py-10 border-t-2 border-background;
}
.login__links a {
  @apply w-fit text-secondary hover:underline;
}
.login__modal-links {
  @apply flex flex-col md:flex-row gap-4 justify-between items-start md:items-center [&>a]:text-secondary;
}
.login__modal [data-announcement] {
  @apply mt-4;
}
.login__modal .login__omniauth {
  @apply mt-6;
}
.login__modal .login__omniauth-separator {
  @apply last:mt-0 last:invisible;
}

.page__accordion {
  @apply border-4 border-background rounded p-4;
}
.page__accordion-trigger {
  @apply flex justify-between items-center;
}
.page__accordion-trigger > :first-child {
  @apply grow;
}
.page__accordion-trigger > :last-child {
  @apply flex-none;
}
.page__accordion-trigger-title {
  @apply h4 text-secondary hover:underline;
}
.page__accordion-trigger-snippet {
  @apply text-gray-2 mt-2;
}
.page__accordion-trigger-arrow {
  @apply w-8 h-8 text-secondary fill-current first:block last:hidden;
}
[role=button][aria-expanded=true] .page__accordion-trigger-arrow {
  @apply first:hidden last:block;
}
.page__accordion-panel {
  @apply border-t border-gray-3 mt-4 pt-4;
}
.page__accordion-panel ul {
  @apply space-y-4;
}
.page__accordion-panel a {
  @apply hover:underline;
}
.page__grid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6;
}

.notification {
  @apply pb-4 last:pb-0 flex items-start justify-between gap-4 transition duration-500;
}
.notification + .notification {
  @apply border-t border-gray-3 pt-4;
}
.notification__wrapper {
  @apply grow flex flex-col-reverse md:flex-row gap-2 md:gap-4;
}
.notification__time {
  @apply w-full md:w-1/6 text-gray-2 break-words flex-none first-letter:uppercase text-sm md:text-md;
}
.notification__snippet {
  @apply flex flex-col grow space-y-1;
}
.notification__snippet-title {
  @apply prose-a:font-semibold hover:prose-a:underline prose-a:text-secondary;
}
.notification__snippet-actions {
  @apply pt-4;
}
.notification__snippet-resource {
  @apply text-sm text-gray-2 flex items-center gap-1;
}
.notification__snippet-resource svg {
  @apply text-gray fill-current;
}
.notification__button {
  @apply bg-background rounded flex items-center gap-1 py-1 px-2 text-xs text-secondary whitespace-nowrap flex-none transition hover:bg-secondary hover:text-white;
}

.profile__avatar {
  @apply w-full h-full rounded-full overflow-hidden drop-shadow-md border-2 border-white;
}
.profile__avatar img {
  @apply w-full h-full object-cover;
}
.profile__avatar-container {
  @apply w-24 h-24 relative;
}
.profile__avatar-badge {
  @apply absolute top-full right-0 -translate-y-full grid place-items-center w-6 h-6 rounded-full overflow-hidden bg-primary border border-white;
}
.profile__avatar-badge svg {
  @apply w-4 h-4 text-white fill-current;
}
.profile__details {
  @apply pb-3 space-y-2;
}
.profile__details-data {
  @apply flex flex-wrap gap-x-6 gap-y-4;
}
.profile__details-item {
  @apply flex items-center space-x-1.5 whitespace-nowrap;
}
.profile__details-item svg {
  @apply w-3.5 h-3.5 text-gray fill-current;
}
.profile__details-item span {
  @apply text-sm text-gray-2 lowercase truncate max-w-xs;
}
.profile__description {
  @apply py-3 last:pb-0 text-sm text-gray-2;
}
.profile__actions-main {
  @apply w-fit mx-auto gap-x-4 gap-y-6 md:gap-4;
  /* overwrite default dropdown styles */
}
.profile__actions-main__dropdown {
  @apply divide-y divide-gray-3 z-20 w-64;
}
.profile__actions-main__dropdown-container {
  @apply relative;
}
.profile__actions-main__dropdown-trigger {
  @apply flex justify-center;
}
.profile__actions-main [id*=dropdown-menu] {
  @apply py-0 mx-0 w-full md:w-auto;
}
.profile__actions-main [id*=dropdown-menu][aria-hidden=true] {
  @apply md:hidden;
}
.profile__actions-main [data-target*=dropdown] {
  @apply [&>svg]:h-auto [&>svg]:w-auto last-of-type:[&>svg]:block;
}
.profile__actions-main [data-target*=dropdown]:hover > svg, .profile__actions-main [data-target*=dropdown]:hover > span {
  @apply text-white;
}
.profile__actions-secondary {
  @apply flex justify-center md:justify-between text-xs gap-2;
}
.profile__tab {
  @apply grow rounded-t;
}
.profile__tab.is-active {
  @apply bg-white z-10 text-secondary font-semibold;
}
.profile__tab-item {
  @apply w-full h-full p-2.5 flex flex-col md:flex-row items-center justify-center gap-2;
}
.profile__tab-item svg {
  @apply w-4 h-4 fill-current;
}
.profile__activity {
  @apply grid md:grid-cols-8 items-start gap-4 md:gap-12 md:first:[&>*]:col-span-2 md:last:[&>*]:col-span-6;
}
.profile__activity__container {
  @apply divide-y divide-gray-3;
}
.profile__badge {
  @apply relative;
}
.profile__badge-banner {
  @apply flex gap-3 bg-background rounded p-4 [&>*:last-child]:space-y-2;
}
.profile__badge-banner svg {
  @apply w-5 h-5 text-tertiary fill-current flex-none;
}
.profile__badge-banner p {
  @apply text-md text-gray-2;
}
.profile__badge-grid {
  @apply grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-12 py-6 md:py-12;
}
.profile__badge-outline {
  --color: var(--tertiary);
  @apply w-32 h-32 mx-auto grid place-items-center rounded-full overflow-hidden bg-[radial-gradient(farthest-side,#F3F4F7_90%,transparent_0_100%),conic-gradient(var(--color)_calc(var(--value)*100%),transparent_0)];
}
.profile__badge-circle {
  @apply rounded-full overflow-hidden w-[90%] h-[90%] m-[5%_auto];
}
.profile__badge-title {
  @apply absolute rounded text-primary bg-white top-full left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 px-1.5 font-semibold;
  box-shadow: 0 4px 6px 0 rgba(211, 211, 211, 0.25);
}
.profile__badge-name {
  @apply text-gray-2 text-center font-semibold mt-4;
}
.profile__badge-description {
  @apply text-gray-2 text-center mt-2;
}
.profile__user {
  @apply flex items-center space-x-1.5;
}
.profile__user-grid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10;
}
.profile__user-avatar {
  @apply w-full h-full rounded-full overflow-hidden drop-shadow-md border-2 border-white transition duration-300;
}
.profile__user-avatar img {
  @apply w-full h-full object-cover;
}
.profile__user-avatar-container {
  @apply w-20 h-20 relative flex-none mr-4;
}
.profile__user-avatar-badge {
  @apply absolute top-full right-0 -translate-y-full grid place-items-center w-4 h-4 rounded-full overflow-hidden bg-primary border border-white;
}
.profile__user-avatar-badge svg {
  @apply w-2.5 h-2.5 text-white fill-current;
}
.profile__user-name {
  @apply h5 text-secondary;
}
.profile__user-nick {
  @apply text-gray-2 lowercase;
}
.profile__user:hover .profile__user-avatar {
  @apply border-tertiary;
}
.profile__user:hover .profile__user-name {
  @apply underline;
}
.profile__group {
  @apply pb-12 mb-14 border-b-2 border-gray-3;
}
.profile__group p {
  @apply mt-4 text-md text-gray-2;
}
.profile__group__description {
  @apply my-8 pt-8 border-t-2 border-gray-3 text-gray-2 font-semibold uppercase;
}
.profile__group__back {
  @apply grid place-items-center absolute top-6 md:top-12 right-0 md:-left-12 rounded-full w-6 h-6 bg-background [&>svg]:text-secondary [&>svg]:fill-current;
}
.profile__group__list {
  @apply my-6 md:my-10 first:mt-0 last:mb-0 flex flex-wrap md:flex-nowrap justify-between items-center gap-4 [&>*:nth-child(1)]:w-full md:[&>*:nth-child(1)]:w-auto md:[&>*:nth-child(2)]:ml-auto;
}
.profile__scaffold__top {
  @apply bg-background [&>*]:mb-0;
}
.profile__scaffold__top-content {
  @apply flex flex-col items-center md:items-start md:flex-row justify-between py-6 md:py-12 gap-6;
}
.profile__scaffold__top-content > *:nth-child(1) {
  @apply flex-none;
}
.profile__scaffold__top-content > *:nth-child(2) {
  @apply w-full md:w-fit grow divide-y divide-gray-3;
}
.profile__scaffold__top-content > *:nth-child(3) {
  @apply max-w-full md:w-1/5 flex-none inline-flex flex-col gap-4;
}
.profile__scaffold__middle {
  @apply bg-background overflow-x-auto [&>*]:mb-0;
}
.profile__scaffold__middle-content {
  @apply flex justify-between text-gray-2;
}
.profile__scaffold__bottom {
  /* stylelint-disable-next-line number-leading-zero */
  @apply border-t-2 border-gray-3 -mt-0.5 [&>*]:mb-0;
}
.profile__scaffold__bottom-content {
  @apply py-6 md:py-12 min-h-[200px] mb-32 relative;
}

.conversation__aside {
  @apply w-full flex md:flex-col items-start justify-between gap-4 md:gap-8;
}
.conversation__aside-actions {
  @apply flex flex-row md:flex-col gap-2 md:gap-8 items-center md:items-start;
}
.conversation__item {
  @apply flex items-center justify-between gap-4 pb-4 last:pb-0;
}
.conversation__item + .conversation__item {
  @apply border-t border-gray-3 pt-4;
}
.conversation__item-avatar {
  @apply flex-none w-8 h-8 rounded-full overflow-hidden drop-shadow-md border-2 border-white grid place-items-center;
}
.conversation__item-snippet {
  @apply grow space-y-2.5 truncate;
}
.conversation__item-snippet-title {
  @apply flex gap-4;
}
.conversation__item-snippet-message {
  @apply text-gray-2 truncate;
}
.conversation__item-snippet-time {
  @apply text-gray-2 text-sm;
}
.conversation__item-unread {
  @apply w-1/12 flex-none text-sm text-white;
}
.conversation__item-unread > * {
  @apply bg-secondary rounded-full font-semibold px-1.5;
}
.conversation__item:hover .conversation__item-avatar {
  @apply border-tertiary;
}
.conversation__item:hover .conversation__item-snippet-title {
  @apply underline;
}
.conversation__title {
  @apply h4 mb-6;
}
.conversation__participants {
  @apply bg-background rounded px-4 py-2 flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-6 mb-14;
}
.conversation__message {
  @apply flex items-start gap-4 w-full md:w-3/4;
}
.conversation__message-text {
  @apply grow bg-background rounded p-4 pt-2 flex flex-col space-y-2 text-gray-2;
}
.conversation__message-sender {
  @apply text-xs text-secondary font-semibold;
}
.conversation__message-time {
  @apply block text-xs;
}
.conversation__reply {
  @apply flex flex-col w-full md:w-2/3 mx-auto;
}
.conversation__reply-container {
  @apply border-t-2 border-background mt-6 py-12;
}
.conversation__reply textarea {
  @apply w-full border border-gray rounded-lg px-4 py-2;
}
.conversation__modal-results {
  @apply mb-20 flex flex-wrap gap-x-8 gap-y-4;
}
.conversation__modal [role=listbox] li, .conversation__modal-results li {
  @apply flex items-center gap-2;
}
.conversation__modal [role=listbox] img, .conversation__modal-results img {
  @apply rounded-full w-6 h-6 object-cover;
}
.conversation__modal [role=listbox] span, .conversation__modal-results span {
  @apply font-semibold text-sm text-secondary;
}
.conversation__modal [role=listbox] small, .conversation__modal-results small {
  @apply font-normal text-gray-2 text-xs;
}
.conversation__modal [role=listbox] svg, .conversation__modal-results svg {
  @apply w-4 h-4 text-primary fill-current;
}
.conversation__modal [role=listbox] .is-group, .conversation__modal-results .is-group {
  @apply flex items-center gap-1;
}

.version__list {
  @apply flex items-center justify-between rounded p-3 bg-background text-secondary text-md font-semibold [&>svg]:hidden;
}
.version__list.is-active {
  @apply bg-background-4 font-bold [&>svg]:block [&>svg]:fill-current;
}
.version__list + .version__list {
  @apply mt-4;
}
.version__author {
  @apply mt-4;
}
.version__selector {
  @apply flex flex-col gap-2;
}
.version__selector-container {
  @apply mt-8 flex items-end gap-4 [&>*]:grow last:[&>*]:flex-none;
}
.version__selector > label {
  @apply text-gray-2 text-lg font-semibold;
}
.version__diff {
  @apply space-y-4;
}
.version__diff-container {
  @apply mt-8 space-y-8;
}
.version__diff-split {
  @apply flex justify-start gap-4 [&>*]:w-1/2 [&_span]:text-sm [&_span]:text-gray-2;
}
.version__diff li {
  @apply p-3 rounded bg-background text-black [&_span]:mr-2 [&_strong]:font-normal;
}
.version__diff li.ins {
  @apply bg-success/20 [&_strong]:bg-success/20;
}
.version__diff li.del {
  @apply bg-alert/20 [&_strong]:bg-alert/20;
}
.version__diff li > ins,
.version__diff li > del,
.version__diff li > * {
  @apply no-underline break-words;
}
.version__diff li + li {
  @apply mt-4;
}

.home__section,
.home .content-block {
  @apply py-8 md:py-12 container;
}
.home__section ~ .content-block,
.home .content-block ~ .content-block {
  @apply border-0;
}
.home .activity__container:not(:last-child) .activity:last-child {
  @apply pb-4 mb-4 border-b border-gray-3;
}
.home__section-content-block {
  @apply flex flex-col gap-8 items-stretch;
}
.home__section-title {
  @apply h2 decorator;
}
.home__section-image {
  /* stylelint-disable-next-line number-leading-zero */
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 21.88%, rgba(0, 0, 0, 0.46421) 60.94%, rgba(0, 0, 0, 0.105) 100%), var(--hero-image);
  @apply bg-center bg-cover;
}
.home__section-header {
  @apply mb-6 md:mb-8 flex flex-col md:flex-row gap-y-6 md:gap-y-0 gap-x-1 items-start md:items-center;
}
.home__section-header .button {
  @apply ltr:md:ml-auto rtl:md:mr-auto;
}
.home__section-content-banner {
  @apply flex flex-col md:flex-row items-center;
}
.home__section-content-banner__title {
  @apply h2 text-white;
}
.home__section-content-banner .editor-content > * {
  @apply text-white;
}
.home__section-participate-grid {
  @apply grid grid-cols-1 md:grid-cols-3 my-12 md:gap-x-8 gap-y-4;
}
.home__section-participate-grid__title {
  @apply h3 mb-2 leading-6;
}
.home__section-participate-grid__text {
  @apply text-md text-gray-2;
}
.home__section-participate-grid__block {
  @apply grid grid-cols-[1.5rem_1fr] gap-2;
}
.home__section-participate-grid__icon {
  @apply w-6 h-6 fill-secondary;
}
.home__menu {
  @apply hidden md:block bg-primary py-4;
}
.home__menu__container {
  @apply flex flex-row justify-center;
}
.home__menu-element {
  @apply relative after:content-[""] after:h-full after:w-[1px] after:absolute after:top-0 after:left-0 after:bg-white after:opacity-60 last:before:content-[""] last:before:h-full last:before:w-[1px] last:before:absolute last:before:top-0 last:before:right-0 last:before:bg-white last:before:opacity-60;
}
.home__menu-element--link {
  @apply py-px px-8 text-white text-lg font-semibold w-full underline decoration-transparent hover:decoration-white transition duration-300 leading-[22px];
}
.home .initiatives__progress-bar .progress-bar__container {
  @apply w-16;
}
.home .initiatives__progress-bar .progress-bar__number {
  @apply text-gray-2 font-normal text-sm;
}
.home .initiatives__progress-bar .progress-bar__units {
  @apply hidden;
}

.search__filter {
  @apply self-stretch;
}
.search__filter [id*=dropdown-menu] {
  @apply gap-0 divide-y divide-background-3 [&>*]:py-4 first:[&>*]:pt-0 last:[&>*]:pb-0 [&>*]:space-y-4 cursor-auto;
}
.search__filter [id*=dropdown-menu] .is-empty {
  @apply pointer-events-none opacity-25;
}
.search__result {
  @apply py-10 first:pt-0 last:pb-0 border-b-2 last:border-0 border-background space-y-8;
}

.cookies {
  @apply container flex flex-col md:flex-row justify-between items-start md:items-center gap-6 md:gap-24 py-6;
}
.cookies__container {
  @apply fixed bottom-0 z-50 bg-white w-full shadow-[0_-4px_6px_rgba(211,211,211,0.25)];
  animation-name: slideInUp;
  animation-duration: 50ms;
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.cookies__left {
  @apply w-full md:w-4/5 flex flex-col md:flex-row items-start md:items-center gap-6;
}
.cookies__left p {
  @apply mt-2 text-sm text-gray-2;
}
.cookies__right {
  @apply w-full md:w-1/5 flex flex-col gap-2;
}
.cookies__category {
  @apply border-4 border-background rounded p-4 my-2 first:mt-0 last:mb-0;
}
.cookies__category-trigger {
  @apply flex justify-between items-center gap-2;
}
.cookies__category-trigger > :last-child {
  @apply grow flex items-center gap-2 last:[&>*]:ml-auto;
}
.cookies__category-trigger > :first-child {
  @apply flex-none;
}
.cookies__category-trigger-title {
  @apply h4 text-secondary;
}
.cookies__category-trigger-snippet {
  @apply text-gray-2 mt-2;
}
.cookies__category-trigger-arrow {
  @apply w-8 h-8 text-secondary fill-current first:block last:hidden;
}
[role=button][aria-expanded=true] .cookies__category-trigger-arrow {
  @apply first:hidden last:block;
}
.cookies__category-panel {
  @apply divide-y divide-gray-3 [&>*]:py-4 [&>p]:text-gray-2;
}
.cookies__category-panel__tr {
  @apply flex items-center justify-between rounded first-of-type:bg-background-4;
}
.cookies__category-panel__th {
  @apply w-1/4 p-2 bg-background-4 text-gray-2 font-bold text-sm;
}
.cookies__category-panel__td {
  @apply w-1/4 p-2 text-gray-2 text-xs;
}
.cookies__category-toggle {
  @apply relative inline-block w-14 h-6 rounded-full;
}
.cookies__category-toggle input {
  @apply opacity-0 w-0 h-0;
}
.cookies__category-toggle-content {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-alert/10 rounded-full before:content-[""] before:absolute before:w-5 before:h-5 before:bottom-0.5 before:left-0.5 before:rounded-full before:bg-alert before:transition before:duration-300;
}
input:focus ~ .cookies__category-toggle-content {
  @apply outline outline-tertiary outline-offset-2 outline-1 transition-none;
}
input:checked ~ .cookies__category-toggle-content {
  @apply bg-success before:bg-white before:translate-x-8;
}
input:disabled ~ .cookies__category-toggle-content {
  @apply bg-background-3 cursor-not-allowed before:bg-gray before:border before:border-gray;
}
.cookies__category-toggle-icon {
  @apply absolute top-1/2 -translate-y-1/2 w-4 h-4 fill-current first-of-type:text-white last-of-type:text-alert first-of-type:hidden last-of-type:block first-of-type:left-2 last-of-type:right-2;
}
input:checked ~ .cookies__category-toggle-icon {
  @apply first-of-type:block last-of-type:hidden;
}
input:disabled ~ .cookies__category-toggle-icon {
  @apply text-gray cursor-not-allowed;
}

.participatory-space__hero {
  @apply backdrop-brightness-[30%];
}
.participatory-space__hero-text {
  @apply container text-center text-white space-y-6 md:px-16 pt-10 pb-14 md:[&_a_+_a]:ml-6;
}
.participatory-space__hero-slogan {
  @apply text-lg font-semibold;
}
.participatory-space__container > :not([id^=html-block]):not([id^=hero]):not([id^=floating-helper]) {
  @apply container;
}
.participatory-space__container > [id^=html-block] {
  @apply w-full prose max-w-none [&+*]:border-t-0;
}
.participatory-space__container .content-block {
  @apply gap-0;
}
.participatory-space__container .content-block__title {
  @apply pb-8;
}
.participatory-space__container .content-block .documents__container > div {
  @apply border-b border-[#D3D5D9] border-t-0 pb-4 pt-6;
}
.participatory-space__container .content-block .documents__dropdown-container {
  @apply border-b border-[#D3D5D9];
}
.participatory-space__container .content-block .documents__collection-trigger {
  @apply bg-transparent max-w-full mt-0 pb-4 pt-6 px-0 rounded-none;
}
.participatory-space__container .content-block .documents__collection-trigger .documents-details {
  @apply flex flex-col gap-2.5;
}
.participatory-space__container .content-block .documents__collection-trigger .documents-details span {
  @apply flex items-center text-lg leading-6 text-[var(--secondary)] font-semibold gap-1.5;
}
.participatory-space__container .content-block .documents__collection-content {
  @apply w-auto ml-6 my-4 pl-4 lg:pl-8 border-l border-[#D3D5D9];
}
.participatory-space__container .content-block .documents__collection-content p {
  @apply pt-4;
}
.participatory-space__container .content-block .documents__collection-content > .documents__container {
  @apply border-t-0 pt-0 mt-0;
}
.participatory-space__container .content-block .documents__collection-content > .documents__container > :last-child {
  @apply border-b-0;
}
.participatory-space__content-block {
  @apply flex flex-col-reverse md:flex-row justify-between items-start [&>*]:w-full;
}
.participatory-space__content-block > :first-child:not(:only-child) {
  @apply md:w-3/4;
}
.participatory-space__content-block > :last-child:not(:only-child) {
  @apply md:w-1/4;
}
.participatory-space-group__content-block {
  @apply grid md:grid-cols-4 gap-4 items-start [&>*]:col-span-3 md:first:[&>*]:col-span-4 md:last:[&>*]:col-span-1 grid-flow-row-dense;
}
.participatory-space-group__content-block-metadata {
  @apply inline-flex flex-wrap gap-x-12 gap-y-4;
}
.participatory-space-group__content-block-metadata > span {
  @apply inline-flex items-center gap-1 text-md text-gray-2;
}
.participatory-space-group__content-block-metadata > span > svg {
  @apply w-3.5 h-3.5 fill-current text-gray;
}
.participatory-space__nav {
  @apply divide-y divide-white [&>li]:py-3.5 first:[&>li]:pt-0 last:[&>li]:pb-0;
}
.participatory-space__nav-container {
  @apply ml-0 md:ml-6 bg-primary p-3 md:p-6 rounded w-full md:w-auto self-start;
}
.participatory-space__nav-container [id*=dropdown-menu] {
  @apply mx-0;
}
.participatory-space__nav-container [data-target*=dropdown] {
  @apply p-0 [&>span]:text-white [&>svg]:text-white;
}
.participatory-space__nav-item {
  @apply flex items-center gap-1 text-white font-semibold hover:underline [&>svg]:fill-current;
}
.participatory-space__metadata {
  @apply flex flex-col md:flex-row items-start gap-8 md:gap-0;
}
.participatory-space__metadata-grid {
  @apply grid grid-cols-1 md:grid-cols-3 gap-y-8;
}
.participatory-space__metadata-grid .participatory-space__metadata-item {
  @apply border-0 px-0;
}
.participatory-space__metadata-item {
  @apply md:px-10 first:pt-0 md:first:pl-0 last:pb-0 md:last:pr-0 border-0 md:border-l-2 md:first:border-l-0 border-background-3 font-semibold text-md text-gray-2;
}
.participatory-space__metadata-item-title {
  @apply flex gap-1 items-center mb-1 uppercase font-normal text-sm text-gray-2 [&>svg]:text-gray [&>svg]:fill-current;
}
.participatory-space__metadata-item > a,
.participatory-space__metadata-item > button {
  @apply text-secondary underline;
}
.participatory-space__metadata-link {
  @apply md:ml-auto flex flex-col items-center gap-1 font-semibold text-secondary hover:underline;
}
.participatory-space__metadata-link-endorsers {
  @apply hidden md:flex items-center justify-start -space-x-2;
}
.participatory-space__metadata-link-endorsers > span {
  @apply rounded-full overflow-hidden border border-white inline-grid w-8 h-8;
}
.participatory-space__metadata-modal {
  @apply font-normal pb-16;
}
.participatory-space__metadata-modal__list {
  @apply space-y-6 relative;
}
.participatory-space__metadata-modal__list-container {
  @apply mt-8 flex flex-col md:flex-row gap-6 md:gap-0 divide-background-3 md:divide-x md:[&>*]:px-10 first:[&>*]:pl-0 last:[&>*]:pr-0;
}
.participatory-space__metadata-modal__list > * {
  @apply relative before:absolute before:w-px before:-z-10 before:left-[18px] before:bg-gray-3 before:h-[calc(100%+1.5rem)] last:before:h-[36px] before:-top-[18px] first:before:top-[18px];
}
.participatory-space__metadata-modal__step {
  @apply flex items-baseline gap-2 text-left;
}
.participatory-space__metadata-modal__step > :first-child {
  @apply flex-none w-9 h-9 rounded-full inline-grid place-items-center text-secondary bg-background;
}
.participatory-space__metadata-modal__step > :last-child {
  @apply flex flex-col gap-1.5 relative;
}
.participatory-space__metadata-modal__step-title {
  @apply text-secondary text-lg;
}
.participatory-space__metadata-modal__step-dates {
  @apply text-gray-2 text-sm whitespace-nowrap;
}
.participatory-space__metadata-modal__step-current {
  @apply absolute -translate-y-full italic text-sm invisible;
}
.participatory-space__metadata-modal__step:hover > :first-child, .participatory-space__metadata-modal__step[aria-expanded=true] > :first-child {
  @apply text-white bg-secondary;
}
.participatory-space__metadata-modal__step[aria-expanded=true] .participatory-space__metadata-modal__step-title {
  @apply font-semibold;
}
.participatory-space__metadata-modal__step.is-active .participatory-space__metadata-modal__step-current {
  @apply visible;
}
.participatory-space__block-grid {
  @apply grid grid-cols-1 md:grid-cols-3 auto-rows-max gap-6 md:gap-16;
}
.participatory-space__block-reference {
  @apply py-4 bg-background text-center text-gray-2 text-xs font-normal;
}
.participatory-space__block-reference.alert {
  @apply bg-alert/25;
}
.participatory-space__block-breadcrumb {
  @apply container py-4 flex items-center gap-4 text-left text-secondary text-md font-normal [&>svg]:fill-current;
}

.participatory-space-about__aside {
  @apply md:pl-10 text-gray-2 w-full;
}
.participatory-space-about__aside-title {
  @apply flex items-center gap-1 mb-6;
}
.participatory-space-about__data {
  @apply py-4 first:pt-0 last:pb-0 font-normal text-md;
}
.participatory-space-about__data-container {
  @apply divide-y-2 divide-background-3 w-full;
}
.participatory-space-about__data-title {
  @apply font-semibold uppercase text-sm block;
}

.omnipresent-banner {
  @apply flex items-center justify-center gap-4 bg-gray-4 text-white text-center px-4 py-2 text-md underline transition first:[&>*]:font-bold hover:bg-black focus:bg-black;
}
.omnipresent-banner svg {
  @apply flex-none text-white fill-current;
}
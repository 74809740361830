.button {
  @apply inline-flex items-center justify-center font-semibold text-center border border-transparent rounded transition cursor-pointer [&>svg]:flex-none [&>svg]:fill-current [&>span]:text-center [&>span]:break-words focus:outline focus:outline-2 focus:outline-offset-2;

  &__xs {
    @apply text-sm [&:not([class*="button\\_\\_text"])]:px-3 [&:not([class*="button\\_\\_text"])]:py-1 gap-1;
  }

  &__sm {
    @apply text-sm [&:not([class*="button\\_\\_text"])]:px-4 [&:not([class*="button\\_\\_text"])]:py-1.5 gap-1.5;
  }

  &__lg {
    @apply text-lg [&:not([class*="button\\_\\_text"])]:px-6 [&:not([class*="button\\_\\_text"])]:py-3 gap-2;
  }

  &__xl {
    @apply text-xl [&:not([class*="button\\_\\_text"])]:px-8 [&:not([class*="button\\_\\_text"])]:py-4 gap-4;
  }

  &__primary {
    @apply bg-primary text-white focus:outline-primary;
  }

  &__secondary {
    @apply bg-secondary text-white focus:outline-secondary;
  }

  &__tertiary {
    @apply bg-tertiary text-white focus:outline-tertiary;
  }

  &__transparent {
    @apply bg-transparent text-white border border-white;

    &-primary {
      @apply border bg-white border-primary text-primary focus:outline-primary;
    }

    &-secondary {
      @apply border bg-white border-secondary text-secondary focus:outline-secondary;
    }

    &-tertiary {
      @apply border bg-white border-tertiary text-tertiary focus:outline-tertiary;
    }
  }

  &__text {
    @apply bg-transparent border-0 p-0 hover:underline text-black;

    &-primary {
      @apply bg-transparent border-0 p-0 hover:underline text-primary;
    }

    &-secondary {
      @apply bg-transparent border-0 p-0 hover:underline text-secondary;
    }

    &-tertiary {
      @apply bg-transparent border-0 p-0 hover:underline text-tertiary;
    }
  }

  &[disabled],
  &.disabled {
    @apply text-gray-2 bg-background-3 border border-gray-2 cursor-not-allowed;
  }

  &.is-hover,
  &:not([disabled]):not([class*="button__text"]):not(label):hover {
    @apply text-white;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      theme("colors.secondary");
  }

  &__pill {
    @apply bg-gray-5 text-secondary mx-0 rounded;
  }

  &__pill:not(:last-child) {
    @apply rounded-r-none;
  }

  &__pill + &__pill {
    @apply rounded-r rounded-l-none;
  }

  &__pill--active {
    @apply bg-secondary text-white;

    .button__pill--count {
      @apply bg-white text-secondary;
    }
  }

  &__pill--count {
    @apply rounded-full bg-secondary text-white inline-grid place-items-center h-[18px] w-[18px] ml-2 text-xs;
  }
}

.layout-main__buttons {
  .button {
    &__sm {
      @apply text-2xl [&:not([class*="button\\_\\_text"])]:px-1.5 [&:not([class*="button\\_\\_text"])]:py-1.5 gap-1.5
      lg:text-sm lg:[&:not([class*="button\\_\\_text"])]:px-4;

      span {
        @apply hidden lg:flex;
      }
    }

    &__transparent {
      &-secondary {
        @apply p-0 border-0 lg:border lg:border-secondary;
      }
    }
  }
}

.endorsers-list {
  @apply space-y-3 opacity-0 -translate-y-full invisible transition-all absolute;

  &__text {
    @apply text-md text-gray-2 ml-4 leading-normal;

    a {
      @apply text-md;
    }
  }

  &__grid {
    @apply flex flex-wrap gap-y-3;
  }

  &__avatar {
    @apply rounded-full overflow-hidden border border-white inline-grid w-6 h-6;
  }

  &__button {
    @apply block ml-auto text-right text-xs text-secondary cursor-pointer;
  }

  &__container {
    @apply w-full flex justify-start -space-x-4 p-0 gap-2;

    > span {
      @apply inline-grid;
    }
  }

  &__modal {
    .list-item__author {
      @apply flex w-full justify-between;

      .author__avatar-container {
        @apply w-8 h-8;
      }

      .button {
        @apply [&>svg]:hidden;
      }
    }

    .list-item__author + .list-item__author {
      --vertical-spacing: 1rem;

      @apply mt-[var(--vertical-spacing)];
    }
  }
}

.participatory-space {
  &__hero {
    @apply backdrop-brightness-[30%];

    &-text {
      @apply container text-center text-white space-y-6 md:px-16 pt-10 pb-14 md:[&_a_+_a]:ml-6;
    }

    &-slogan {
      @apply text-lg font-semibold;
    }
  }

  &__container {
    > :not([id^="html-block"]):not([id^="hero"]):not([id^="floating-helper"]) {
      @apply container;
    }

    > [id^="html-block"] {
      @apply w-full prose max-w-none [&+*]:border-t-0;
    }

    .content-block {
      @apply gap-0;

      &__title {
        @apply pb-8;
      }

      .documents {
        &__container > div {
          @apply border-b border-[#D3D5D9] border-t-0 pb-4 pt-6;
        }

        &__dropdown-container {
          @apply border-b border-[#D3D5D9];
        }

        &__collection-trigger {
          @apply bg-transparent max-w-full mt-0 pb-4 pt-6 px-0 rounded-none;

          .documents-details {
            @apply flex flex-col gap-2.5;

            span {
              @apply flex items-center text-lg leading-6 text-[var(--secondary)] font-semibold gap-1.5;
            }
          }
        }

        &__collection-content {
          @apply w-auto ml-6 my-4 pl-4 lg:pl-8 border-l border-[#D3D5D9];

          p {
            @apply pt-4;
          }

          & > .documents__container {
            @apply border-t-0 pt-0 mt-0;

            & > :last-child {
              @apply border-b-0;
            }
          }
        }
      }
    }
  }

  &__content-block {
    @apply flex flex-col-reverse md:flex-row justify-between items-start [&>*]:w-full;

    & > :first-child:not(:only-child) {
      @apply md:w-3/4;
    }

    & > :last-child:not(:only-child) {
      @apply md:w-1/4;
    }
  }

  &-group__content-block {
    @apply grid md:grid-cols-4 gap-4 items-start [&>*]:col-span-3 md:first:[&>*]:col-span-4 md:last:[&>*]:col-span-1 grid-flow-row-dense;

    &-metadata {
      @apply inline-flex flex-wrap gap-x-12 gap-y-4;

      & > span {
        @apply inline-flex items-center gap-1 text-md text-gray-2;

        & > svg {
          @apply w-3.5 h-3.5 fill-current text-gray;
        }
      }
    }
  }

  &__nav {
    @apply divide-y divide-white [&>li]:py-3.5 first:[&>li]:pt-0 last:[&>li]:pb-0;

    &-container {
      @apply ml-0 md:ml-6 bg-primary p-3 md:p-6 rounded w-full md:w-auto self-start;

      [id*="dropdown-menu"] {
        @apply mx-0;
      }

      [data-target*="dropdown"] {
        @apply p-0 [&>span]:text-white [&>svg]:text-white;
      }
    }

    &-item {
      @apply flex items-center gap-1 text-white font-semibold hover:underline [&>svg]:fill-current;
    }
  }

  &__metadata {
    @apply flex flex-col md:flex-row items-start gap-8 md:gap-0;

    &-grid {
      @apply grid grid-cols-1 md:grid-cols-3 gap-y-8;
    }

    &-grid &-item {
      @apply border-0 px-0;
    }

    &-item {
      @apply md:px-10 first:pt-0 md:first:pl-0 last:pb-0 md:last:pr-0 border-0 md:border-l-2 md:first:border-l-0 border-background-3 font-semibold text-md text-gray-2;

      &-title {
        @apply flex gap-1 items-center mb-1 uppercase font-normal text-sm text-gray-2 [&>svg]:text-gray [&>svg]:fill-current;
      }

      > a,
      > button {
        @apply text-secondary underline;
      }
    }

    &-link {
      @apply md:ml-auto flex flex-col items-center gap-1 font-semibold text-secondary hover:underline;

      &-endorsers {
        @apply hidden md:flex items-center justify-start -space-x-2;

        > span {
          @apply rounded-full overflow-hidden border border-white inline-grid w-8 h-8;
        }
      }
    }

    &-modal {
      @apply font-normal pb-16;

      &__list {
        @apply space-y-6 relative;

        &-container {
          @apply mt-8 flex flex-col md:flex-row gap-6 md:gap-0 divide-background-3 md:divide-x md:[&>*]:px-10 first:[&>*]:pl-0 last:[&>*]:pr-0;
        }

        > * {
          @apply relative before:absolute before:w-px before:-z-10 before:left-[18px] before:bg-gray-3 before:h-[calc(100%+1.5rem)] last:before:h-[36px] before:-top-[18px] first:before:top-[18px];
        }
      }

      &__step {
        @apply flex items-baseline gap-2 text-left;

        > :first-child {
          @apply flex-none w-9 h-9 rounded-full inline-grid place-items-center text-secondary bg-background;
        }

        > :last-child {
          @apply flex flex-col gap-1.5 relative;
        }

        &-title {
          @apply text-secondary text-lg;
        }

        &-dates {
          @apply text-gray-2 text-sm whitespace-nowrap;
        }

        &-current {
          @apply absolute -translate-y-full italic text-sm invisible;
        }

        &:hover > :first-child,
        &[aria-expanded="true"] > :first-child {
          @apply text-white bg-secondary;
        }

        &[aria-expanded="true"] &-title {
          @apply font-semibold;
        }

        &.is-active &-current {
          @apply visible;
        }
      }
    }
  }

  &__block {
    &-grid {
      @apply grid grid-cols-1 md:grid-cols-3 auto-rows-max gap-6 md:gap-16;
    }

    &-reference {
      @apply py-4 bg-background text-center text-gray-2 text-xs font-normal;

      &.alert {
        @apply bg-alert/25;
      }
    }

    &-breadcrumb {
      @apply container py-4 flex items-center gap-4 text-left text-secondary text-md font-normal [&>svg]:fill-current;
    }
  }
}

.participatory-space-about {
  &__aside {
    @apply md:pl-10 text-gray-2 w-full;

    &-title {
      @apply flex items-center gap-1 mb-6;
    }
  }

  &__data {
    @apply py-4 first:pt-0 last:pb-0 font-normal text-md;

    &-container {
      @apply divide-y-2 divide-background-3 w-full;
    }

    &-title {
      @apply font-semibold uppercase text-sm block;
    }
  }
}
